import React, { useEffect, useRef, useState } from 'react';
import "../Trashies/Trashie.css";
import Trashapcan from "../commonstyle/images/Trashapcan.png"
import CityIcon from "../commonstyle/images/CityIcon.png"
import { InfoWindow } from 'google-maps-react';
import OverlappingMarkerSpiderfier from 'overlapping-marker-spiderfier';
import {t} from '../Translation/Translation';


const GMap = (markerLists, centers) => {
  const googleMapRef = useRef(null);
  let googleMap = null;

  //icon for marker
  // const icon= 'https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Marker-Outside-Pink.png'

  const center = markerLists.centers
  const status = markerLists.status
  // list of the marker object
  const markerList = markerLists.markerLists


  useEffect(() => {
    if (markerList !== 'None' && center !== 'None') {
      if (markerList.length === 0) {
        googleMap = initGoogleMap();
      } else {
        if (status === 1){
          googleMap = initGoogleMap();
          const options = { legWeight: 2, circleFootSeparation: 30 };
          const oms = new OverlappingMarkerSpiderfier(googleMap, options);
          const openedInfoWindow = []
          function infoWindowclose() {
            if (openedInfoWindow[0] !== undefined) {
              openedInfoWindow[0].close();
              openedInfoWindow.pop();
            }
          }

          let bounds = new window.google.maps.LatLngBounds();

          markerList.map(x => {
            const marker = createMarker(x);
            oms.addMarker(marker);
            const infowindow = createInfo('<div>' + '<h6 class="small p-0 m-0">' + x.title + ',</h6>' + '<small class="m-0 p-0">' + x.location + '</small> </br>' + '<a href= "/trashdetail/' + x.complaint_id + '"><small>'+ t('Click for details')+ '</small></a></div>')

            marker.addListener("click", () => {
              infoWindowclose()
              infowindow.open(x, marker);
              openedInfoWindow.push(infowindow)
            });
            oms.addListener('spiderfy', function(markers) {
              infoWindowclose();
            });
            bounds.extend(marker.position, marker.zoom);
          });
          googleMap.fitBounds(bounds); // the map to contain all markers
        }else{
          googleMap = initGoogleMap();
          const options = { legWeight: 2, circleFootSeparation: 30 };
          const oms = new OverlappingMarkerSpiderfier(googleMap, options);
          const openedInfoWindow = []
          function infoWindowclose() {
            if (openedInfoWindow[0] !== undefined) {
              openedInfoWindow[0].close();
              openedInfoWindow.pop();
            }
          }
          let bounds = new window.google.maps.LatLngBounds();

          markerList.map(x => {
            const marker = createCityMarker(x);
            oms.addMarker(marker);
            const infowindow = createInfo('<div>' + '<h6 class="small p-0 m-0">' + x.title + '</h6>' + '</div>')

            marker.addListener("click", () => {
              infoWindowclose()
              infowindow.open(x, marker);
              openedInfoWindow.push(infowindow)
            });
            oms.addListener('spiderfy', function(markers) {
              infoWindowclose();
            });
            bounds.extend(marker.position, marker.zoom);
          });
          googleMap.fitBounds(bounds); // the map to contain all markers
        }
      }
    }
    else {
      googleMap = initGoogleMap();
    }
  }, [markerLists, centers, status]);


  // initialize the google map
  const initGoogleMap = () => {
    if (center !== 'None'){
      return new window.google.maps.Map(googleMapRef.current, {
        center: center,
        zoom: 12
      });
    }
  }

  // create marker on google map
  const createMarker = (markerObj) => new window.google.maps.Marker({
    position: { lat: markerObj.lattitude, lng: markerObj.longitude },
    map: googleMap,
    icon: {
      url: Trashapcan,
      scaledSize: new window.google.maps.Size(35, 35)
    },
  });

  const createCityMarker = (markerObj) => new window.google.maps.Marker({
    position: { lat: markerObj.lattitude, lng: markerObj.longitude },
    map: googleMap,
    icon: {
      url: CityIcon,
      scaledSize: new window.google.maps.Size(35, 35)
    },
  });

  // create marker on google map
  const createInfo = (data) => new window.google.maps.InfoWindow({
    content: data,
  });

  return <div className="dashboard-map"
    ref={googleMapRef}
  // style={{ width: 1100, height: 500 }}
  />
}

export default GMap;
