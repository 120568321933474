import React, { useState, useEffect } from "react";
import "../Login/Login.css";
import { LocationsUrl } from "../Routes/ApiEndPoints";
import { APICALL as AXIOS } from "../Services/AxiosServices";
import GMap from './Map'

export default function GetMap(props) {

  useEffect(() => {
    let val = {}
    let data = {'customer_id': localStorage.getItem('customer_id'), 'profile_id': localStorage.getItem('id'), 'role': localStorage.getItem('role')}
    AXIOS.service(LocationsUrl, "POST", data)
      .then(result => {
        if (result.status === 1){
          setStatus(1)
          var locationsData = result.location_list
          Object.keys(locationsData).map((value, i) => {
            val = {
              lat: locationsData[value].lattitude,
              lng: locationsData[value].longitude,
              title: locationsData[value].title,
              complaint_id: locationsData[value].complaint_id,
              location: locationsData[value].location
            }
          })
          setLocations(locationsData)
          setCenter(val)
        }
        else {
          setStatus(0)
          setLocations(result.location_list)
          setCenter({lat : result.location_list[0].lattitude, lng: result.location_list[0].longitude})
        }
      })
  }, [props.statusupdate]);

  const [center, setCenter] = useState('None');
  const [locations, setLocations] = useState('None');
  const [status, setStatus] = useState(1);

  return (
    <section>
      <GMap markerLists={locations} centers={center} status={status} />
    </section>
  );
}
