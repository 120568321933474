import React, { useState, useEffect } from "react";
import "../Login/Login.css";
import Alert from 'react-bootstrap/Alert'
import { CreateFixerUrl } from "../Routes/ApiEndPoints";
import { APICALL as AXIOS } from "../Services/AxiosServices";
import PhoneInput from 'react-phone-input-2';
import { useHistory } from "react-router-dom";
import {t} from '../Translation/Translation';


export default function CreateFixer() {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const [customer, setCustomer] = useState("");
  const [type, setType] = useState("");
  const history = useHistory();


  useEffect(() => {
    setCustomer(localStorage.getItem('customer_id'))

  }, [customer]);

  //Function to clear form
  const clearForm = () => {
      setFirstname("")
      setLastname("")
      setEmail("")
      setMobile("")
  }

  //Function to call create fixer api
  function handleSubmit(event) {
    event.preventDefault();
    let data = {
        "first_name": firstname,
        "last_name": lastname,
        "email":email,
        "phone_number":('+' + mobile),
        "customer":customer,
      }

    AXIOS.service(CreateFixerUrl, "POST", data)
        .then(result => {
         if (result['status'] === 200) {
            setType("success")
            setMessage(t(result['message']));
            clearForm();
         } else {
            setType("danger")
            setMessage(t(result['message']));
          }
        })
        .catch(() => {
            setType("danger")
            setMessage(t("This email already exist"));
          })
    }


  return (
    <section className="content">
      <div className="container">
        <div className="row justify-content-md-center rounded mx-0 create-fixer-box">
          <div className="col-md-10 col-lg-8 pt-3 pb-4 bg-white shadow fixer-create-box">
            <div id="first">
                <div className="col-md-12 my-3 title">
                    <h1 className="font4">{t('Create fixer')}</h1>
                </div>
                <div className="col-md-12">
                    {message.length > 0 && (
                    <Alert variant={type} onClose={() => setMessage("")} dismissible>
                        <p className="font8">{t(message)}</p>
                    </Alert>
                    )}
                </div>
                <form onSubmit={handleSubmit}>
                <div className="col-md-12 form-inline mb-3">
                        <label className="col-md-3 col-sm-3 pl-0 justify-content-start font8">{t('First name')}*</label>
                        <input type="text" name="firstname" className="col-md-9 col-sm-9 form-control bg-light" value={firstname} id="email" placeholder={t("Enter first name")} onChange={(e) => setFirstname(e.target.value)} required title={firstname ? null : t('Please fill in this field')} />
                    </div>
                    <div className="col-md-12 form-inline mb-3">
                        <label className="col-md-3 col-sm-3 pl-0 justify-content-start font8">{t('Last name')}*</label>
                        <input type="text" name="lastname" className="col-md-9 col-sm-9 form-control bg-light" value={lastname} id="email" placeholder={t("Enter last name")} onChange={(e) => setLastname(e.target.value)} required title={lastname ? null : t('Please fill in this field')} />
                    </div>
                    <div className="col-md-12 form-inline mb-3">
                        <label className="col-md-3 col-sm-3 pl-0 justify-content-start font8">{t('Email')}*</label>
                        <input type="text" name="email" className="col-md-9 col-sm-9 form-control bg-light" value={email} id="email" placeholder={t("Enter email")} onChange={(e) => setEmail(e.target.value)} required title={email ? null : t('Please fill in this field')} />
                    </div>
                    <div className="col-md-12 col-12 form-inline countyNumber ">
                        <label className="justify-content-start width-10 padd-none number-lable font8">{t('Phone number')}</label>
                        <PhoneInput
                            country={"be"}
                            autocompleteSearch={false}
                            value={mobile}
                            onChange={(phone) => setMobile(phone)}
                        />
                    </div>
                  <div className="col-md-12 mt-3 ">
                    <button type="submit"  className="btn fixer-button font-weight-bold text-white float-right font-para font8" > {t('Create fixer')} </button>
                    <button type="cancel" className="btn mr-3 mybtn font-weight-bold font-para text-white float-right font8" onClick={() => history.push('/')} > {t('Cancel')} </button>
                  </div>
                </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
