import React from 'react';
import Chart from "react-google-charts";
import "./header.css";
import {t} from '../Translation/Translation';

export default function Graph(props) {

    const {graphList} = props;

        return (
            <div className="noHover width-100 pr-1">
                <div></div>
                <small className="mb-0 pl-1 font9">{t(props.title)}</small>
                {graphList !== undefined &&
                    <Chart
                        width={'100%'}
                        height={'30px'}
                        chartType="BarChart"
                        loader={<div>{t('Loading Chart')}</div>}
                        data={[
                            ['Category', 'Count', { role: 'style' }],
                            ['new', graphList['new_count'], '#6c757d'],
                            ['open', graphList['open_count'], '#ffc107'],
                            ['closed', graphList['closed_count'], '#169c02'],
                            ['reject', graphList['reject_count'], '#ea3114'],
                        ]}
                        options={{

                            chartArea: { width: '100%', height: '100%' },
                            legend: { position: "none" },
                            hAxis: {
                                minValue: 0,
                                ticks: [0, 1]
                            },
                            isStacked: false,
                        }}
                    />}
            </div>
        );
}


