import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {APICALL as AXIOS} from '../Services/AxiosServices';
import {PaymentSucces, PaymentVerify, PaymentCapture} from '../Routes/ApiEndPoints';
import {Container, Row, Col, Alert, Spinner} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {t} from "../Translation/Translation";
import LoadingIcon from "../Services/LoadingIcon";

export function Payment() {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [paymentStatus, setPaymentStatus] = useState('success');
    const [purchaseDetails, setPurchaseDetails] = useState({});
    const dispatch = useDispatch();


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const order_id = queryParams.get('transaction_id');
        const customer_id = queryParams.get('customer_id');
        const package_id = queryParams.get('package_id');
        const p_id = localStorage.getItem('p_id');

        const verifyAndFinalizePayment = async () => {
            try {
                const verifyPaymentResponse = await verifyPayment(order_id, customer_id, package_id, p_id);
                if (!verifyPaymentResponse.verified) {
                    setPaymentStatus('failure');
                }
                const transaction_id = verifyPaymentResponse.transaction_id;
                const capturePaymentResponse = await capturePayment(transaction_id, order_id, customer_id, package_id, p_id);
                if (capturePaymentResponse['Status'] !== 'CAPTURED') {
                    setPaymentStatus('failure');
                }
                await finalizePayment(order_id, customer_id, package_id, p_id);
            } catch (error) {
                console.error('Error while processing payment:', error);
                setPaymentStatus('failure');
            }
            setLoading(false);
        }
        verifyAndFinalizePayment();
    }, [location.search]);

    const finalizePayment = async (order_id, customer_id, package_id, p_id) => {
        const finalizedPaymentResponse = await AXIOS.service(PaymentSucces, 'POST', {
            order_id,
            customer_id,
            package_id,
            p_id
        });
        if (finalizedPaymentResponse) {
            setPaymentStatus(finalizedPaymentResponse.message ? 'success' : 'failure');
            setPurchaseDetails(finalizedPaymentResponse);
        } else {
            setPaymentStatus('failure');
        }
    }

    const verifyPayment = async (order_id, customer_id, package_id, p_id) => {
        return await AXIOS.service(PaymentVerify, 'POST', {
            order_id,
            customer_id,
            package_id,
            p_id
        });
    }

    const capturePayment = async (transaction_id, order_id, customer_id, package_id, p_id) => {
        return await AXIOS.service(PaymentCapture, 'POST', {
            transaction_id,
            order_id,
            customer_id,
            package_id,
            p_id
        });
    }

    const capitalizeFirstLetter = (name) => {
        if (name) {
            return name.charAt(0).toUpperCase() + name.slice(1);
        }
    }

    return (
        <Container className="mt-5">
            {loading ? (
                <div className="center-screen">
                    <LoadingIcon />
                </div>
            ) : (
                <Row className="justify-content-md-center">
                    <Col md={8}>
                        {paymentStatus === 'success' ? (
                            <Alert variant="success" className="p-4">
                                <h4 className="alert-heading text-center">{t('Payment Successful!')}</h4>
                                <p className="text-center">{t('Thank you for your purchase. Your transaction has been successfully processed.')}</p>
                                <hr/>
                                <div className="text-center">
                                    <p className="mb-0">{t('Details:')}</p>
                                    <br></br>
                                    <div className="d-flex justify-content-center">
                                        <ul className="list-unstyled text-left mr-3">
                                            <li>{t('Price:')}</li>
                                            <li>{t('Tokens:')}</li>
                                            <li>{t('Package Name:')}</li>
                                        </ul>
                                        <ul className="list-unstyled text-left">
                                            <li>
                                                <strong>{purchaseDetails.price ? purchaseDetails.price + t('$') : 'Loading'}</strong>
                                            </li>
                                            <li>
                                                <strong>{purchaseDetails.token_count ? purchaseDetails.token_count : 'Loading'}</strong>
                                            </li>
                                            <li>
                                                <strong>{capitalizeFirstLetter(purchaseDetails.name) + " " + t('Package') === 'undefined Package' ? "Loading" : capitalizeFirstLetter(purchaseDetails.name) + " " + t('Package')}</strong>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Alert>
                        ) : (
                            <Alert variant="danger" className="p-4">
                                <h4 className="alert-heading text-center">{t('Payment Failed')}</h4>
                                <p className="text-center">{t('Unfortunately, your payment could not be processed at this time.')}</p>
                                <hr/>
                                <p className="text-center mb-0">{t('Please try again later or contact support if the problem persists.')}</p>
                            </Alert>
                        )}
                    </Col>
                </Row>
            )}
        </Container>
    );
}
