import {Table, Pagination, Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {APICALL as AXIOS} from "../Services/AxiosServices";
import {GetTokenHistory, FetchTokenPackageHistory} from "../Routes/ApiEndPoints";
import {t} from "../Translation/Translation";
import {useHistory} from "react-router-dom";
import BackButton from "../commonstyle/images/back_button.png";

export default function TokenHistoryPage() {
    const [tokenHistory, setTokenHistory] = useState([]);
    const [tokenPackageHistory, setTokenPackageHistory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const history = useHistory();

    useEffect(() => {
        const fetchTokenHistory = async () => {
            const customer_id = localStorage.getItem('customer_id');
            const response = await AXIOS.service(GetTokenHistory, 'POST', {
                customer_id: customer_id,
            });
            if (response && Array.isArray(response.complaints)) {
                setTokenHistory(response.complaints.reverse());
            } else {
                console.error("Response does not contain complaints:", response);
            }
        };

        fetchTokenHistory();
    }, []);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        return tokenHistory.slice(startIndex, startIndex + itemsPerPage);
    };

    const getPageNumbers = () => {
        const totalPages = Math.ceil(tokenHistory.length / itemsPerPage);
        let pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(i);
        }
        return pages;
    };

    const formatDate = (date) => {
        return new Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        }).format(new Date(date));
    };

    const handleTitleClick = (id) => {
        history.push(`/trashdetail/${id}`);
    };

    return (
        <>
            <div className="container-fluid main-box">
                <div className="row title-color pl-0 mt-0 font-weight-bold">
                    <div className="col-md-12 col-12">
                        <img
                            className="backbutton float-left cursor"
                            src={BackButton}
                            onClick={history.goBack}
                            title={t('Click to go back')}
                            alt={"back button"}
                        ></img>
                        <h3 className="detailpage-title ml-5 font2">{t('Dashboard')}</h3>
                    </div>
                </div>
                <div className="container mx-auto mt-8 p-6 bg-white rounded-lg shadow-md pb-2 pt-3">
                    <h2 className="text-2xl font-bold mb-4">{t('AI Assistance history')}</h2>
                    <Table striped bordered hover className="mb-4">
                        <thead>
                        <tr>
                            <th>{t('Trashie name')}</th>
                            <th>{t('Used on')}</th>
                            <th>{t('Unit(s) consumed')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Array.isArray(tokenHistory) && tokenHistory.length > 0 ? (
                            getPaginatedData().map((history, index) => (
                                <tr key={index}>
                                    <td>
                                        <a
                                            href=""
                                            onClick={() => handleTitleClick(history.id)}
                                            style={{color: "black", cursor: "pointer"}}
                                        >
                                            {history.title}
                                        </a>
                                    </td>
                                    <td>{formatDate(history.date)}</td>
                                    <td>{"1"}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4">{t('No history found')}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                    {tokenHistory.length > itemsPerPage && (
                        <Pagination className="flex justify-center mb-4">
                            {getPageNumbers().map((number) => (
                                <Pagination.Item
                                    className="bg-gray-300 mx-1"
                                    key={number}
                                    active={number === currentPage}
                                    onClick={() => handlePageChange(number)}
                                    linkStyle={{"color": "white", "background-color": "orange"}}
                                >
                                    {number}
                                </Pagination.Item>
                            ))}
                        </Pagination>
                    )}
                </div>
            </div>
        </>
    );
}
