import React, { useState } from "react";
import "./Login.css";
import Alert from 'react-bootstrap/Alert'
import { ForgotPasswordUrl } from "../Routes/ApiEndPoints";
import { APICALL as AXIOS } from "../Services/AxiosServices";
import { t } from '../Translation/Translation';
import TrashCanImage from "../commonstyle/images/Trashapcan.png"

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");


  function handleSubmit(event) {
    event.preventDefault();

    let data = {
      "email": email,
    }

    AXIOS.service(ForgotPasswordUrl, "POST", data)
      .then(result => {
        if (result['status'] === 200) {
          setType("success")
          setMessage(t(result['message']));
        } else {
          setType("danger")
          setMessage(t(result['message']));
        }
      })
      .catch(error => {
        setType("danger")
        setMessage(t("This email is not registered"));
      })
  }


  return (
    <section>
      <div className="container">
        {/* <div className="col-md-12"> */}
        <div className="row py-3 pb-4">
        {/* <div className="col-md-6 float-left"><img className="trash-can-main" src={TrashCanImage} /></div> */}
          {/* <div className="col-md-6 float-left py-3 login"> */}
          <div className="col-md-6 forgot-screen-message">
            {message.length > 0 && (
              <Alert variant={type} onClose={() => setMessage("")} dismissible>
                <p>{(message)}</p>
              </Alert>
            )}
          </div>
          {/* <div className="col-md-6 py-3 pb-4 shadow border login float-right bg-white"> */}
          <div className="col-md-6 py-3 pb-4 shadow border bg-white forgot-screen">
              <h2 className="col-md-12 mb-4 mt-2 font2 title"> Forgot password</h2>

              <form onSubmit={handleSubmit}>
                <div className="col-md-12 form-inline padd-none">
                  <label className="col-md-12 col-lg-2 col-sm-2 pl-0 justify-content-start padd-none">Email:</label>
                  <input type="text" name="email" className="col-lg-10 col-sm-10 col-md-12 form-control bg-light" value={email} id="email" aria-describedby="emailHelp" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div className="col-md-12 mt-4 padd-none">
                  <button type="submit" className="btn mybtn font-weight-bold font8 text-white font-para float-right" > Continue </button>
                </div>
                <div className="col-md-12 mt-4">
                  <a className="btn mr-3 mybtn font-weight-bold text-white font-para font8 float-right" href='/login'> Cancel </a>
                </div>
              </form>
          </div>
        </div>
      </div>
    </section>
  );
}
