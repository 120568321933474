import React from 'react';
import {Route} from 'react-router-dom';
import DashBoard from '../HomeLayout/dashboard';
import Login from '../Login/Login';
import ForgotPassword from '../Login/ForgotPassword';
import CreateFixer from '../Fixer/CreateFixer';
import TrashList from '../Trashies/Trashies';
import TrashDetail from '../Trashies/TrashDetails';
import MyProfileSettings from '../MyProfileSetting/Myprofile'
import ManageFixers from '../Fixer/Managefixer';
import AssignedTasks from '../Fixer/AssignedTasks';
import TokenHistoryPage from '../TokenHistory/TokenHistoryPage';
import TokenPackagePurchaseHistory from '../TokenHistory/TokenPackagePurchaseHistoryPage';

const langRegex = "(en|nl|fr)?";
const langPath =  `/:lang${langRegex}`;

const BaseRouter = () => (
    <div>
        {/* route starts here */}
        <Route exact path={`/:lang${langRegex}/login`} component={Login} />
        <Route exact path={`/:lang${langRegex}/forgotpassword`} component={ForgotPassword} />
        <Route exact path={`/:lang${langRegex}/createfixer`} component={CreateFixer} />
        <Route exact path={`/:lang${langRegex}/trashlist/:status/:id`} component={TrashList} />
        <Route exact path={`/:lang${langRegex}/trashdetail/:id`} component={TrashDetail} />
        <Route exact path={`/:lang${langRegex}/myaccount`} component={MyProfileSettings} />
        <Route exact path={`/:lang${langRegex}/managefixer`} component={ManageFixers} />
        <Route exact path={`/:lang${langRegex}/assignedtasks/:id`} component={AssignedTasks} />
        <Route exact path={`/:lang${langRegex}/token-history`} component={TokenHistoryPage}/>
        <Route exact path={`/:lang${langRegex}/token-package-purchase-history`} component={TokenPackagePurchaseHistory}/>
        <Route exact path={langPath}  component={DashBoard} />
    </div>
);

export default BaseRouter;
