
import React, { useState, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Alert from 'react-bootstrap/Alert'
import { t } from '../Translation/Translation';
import { useHistory } from 'react-router-dom';

export default function CommonPopup(props) {
    const history = useHistory();
    const [emaildata, setData] = useState();
    const [subject, setSubject] = useState();
    const [email, setEmail] = useState(props.email);
    const [popupstate, Setpopupstate] = useState();
    const [message, setMessage] = useState();


    const CloseError = () => {
        Setpopupstate(false)

    }

    const CloseEmailPopup = () => {
        if (props.type === 'mail') {
            if (emaildata === undefined || subject === undefined || emaildata === '' || subject === '' || email == undefined || email == '') {
                Setpopupstate(true)
                setMessage(t('Please fill required data'))
            } else {
                props.onConfirm(emaildata, subject, email)
                Setpopupstate(false)
                setMessage('')
            }
        } else {
            props.onConfirm();
            Setpopupstate(false)
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size={props.size ? props.size : "lg"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="title-color font-head font3">
                    {t(props.title)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(props.type) === 'mail' && popupstate && <Alert variant="danger" onClose={() => CloseError()} dismissible>
                    {t(message)}
                </Alert>}
                {(props.type) === 'mail' ?
                    <form>
                        {!props.email &&<Alert variant="warning" >
                            {t("User email does not exist.")}
                        </Alert>}
                        <div className="col-md-12 col-12 padd-none form-inline mb-4 pl-0 form-allign-popup">
                            <label className="col-md-2 col-12 padd-none font-head justify-content-start">{t('Email to')}:* </label>
                            <input className="col-md-10 col-12 form-control font-para" value={email} disabled={props.email ? true : false} onChange={(e) => setEmail(e.target.value)}></input>
                        </div>
                        <div className="col-md-12 col-12 padd-none form-inline mb-4 pl-0 form-allign-popup">
                            <label className="col-md-2 col-12 padd-none font-head justify-content-start">{t('Subject')}:* </label>
                            <input className="col-md-10 col-12 form-control font-para" value={subject} placeholder={t("Add subject")} onChange={(e) => setSubject(e.target.value)}></input>
                        </div>
                        <div className="col-md-12 col-12 padd-none form-inline mb-4 p-0">
                            <label className="col-md-2 col-12 padd-none font-head justify-content-start">{t('Body')}:* </label>
                            <CKEditor
                                className="col-md-10 col-12 font-para"
                                editor={ClassicEditor}
                                data=""
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setData(data)
                                }}
                            />
                        </div>
                    </form> :
                    <p>{t(props.body)}</p>}
            </Modal.Body>
            <Modal.Footer>
                <Button className="head-sect react-button font-para font8" onClick={() => history.push("/")}>
                    {t('Cancel')}
                </Button>
                {props.status && <Button className="head-sect mr-3 react-button font8 font-para" onClick={() => CloseEmailPopup()}>
                    {t('Confirm')}
                </Button>}
            </Modal.Footer>
        </Modal>
    );
}

