import React, { Component } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import Trashapcan from "../commonstyle/images/Trashapcan.png"

export class MapContainer extends Component {
   state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
   };

   onMarkerClick = (props, marker, e) =>
      this.setState({
         selectedPlace: props,
         activeMarker: marker,
         showingInfoWindow: true
      });

   onMapClicked = (props) => {
      if (this.state.showingInfoWindow) {
         this.setState({
            showingInfoWindow: false,
            activeMarker: null
         })
      }
   };

   render() {
      const style = {
         position: 'relative',
         width: '100%',
         height: '38vh',
         border: '1px solid lightgrey'
      }
      const containerStyle = {
         position: 'relative',
         width: '100%',
         height: '100%'
      }
      return (
         <Map google={this.props.google}
            containerStyle={containerStyle}
            style={style}
            onClick={this.onMapClicked}
            initialCenter={{
               lat: this.props.lats,
               lng: this.props.long
            }} zoom={14}>

            <Marker onClick={this.onMarkerClick}
               title={this.props.mapaddress}
               icon={{
                  url: Trashapcan,
                  scaledSize: new window.google.maps.Size(35, 35)
               }}
            />
            <InfoWindow
               marker={this.state.activeMarker}
               visible={this.state.showingInfoWindow}>
               <div>
                  <h6>{this.props.mapaddress}</h6>
               </div>
            </InfoWindow>

         </Map>
      );
   }
}

export default GoogleApiWrapper({
   apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
})(MapContainer)
