import React from 'react';
import {useState, useEffect} from "react";
import "./App.css";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import BaseRouter from "./Routes/routes";
import {AppContext} from "./Routes/contextLib";
import HeaderSection from './HomeLayout/header';
import LoadingIcon from './Services/LoadingIcon';
import {fetchAllTranslations} from './Routes/ApiEndPoints';
import {APICALL as AXIOS} from "./Services/AxiosServices";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import {Payment} from "./Payment/Payment";

/*
i18n
    .use(LanguageDetector)
    .use(initReactI18next) // Add this line
    .init({
       resources: {
          nl: {
             translation: nlTranslation,
          },
          en: {
             translation: enTranslation,
          },
          fr: {
             translation: frTranslation,
          },
       },
       fallbackLng: 'nl',
    });
*/


function App() {
    const [isAuthenticated, userHasAuthenticated] = useState(JSON.parse(localStorage.getItem("isAuthenticated")));
    const [activeLanguage, setActiveLanguage] = useState(localStorage.getItem('language'));

    if (activeLanguage === null) {
        setActiveLanguage('en');
    }

    useEffect(() => {
        let data = {'user_id': (localStorage.getItem("id"))}
        if (isAuthenticated) {
            AXIOS.service(fetchAllTranslations, "POST")
                .then(result => {
                    localStorage.setItem('translations', JSON.stringify(result));
                })
        }
    })


    return (
        <div className={isAuthenticated ? "main-background" : "main-background-with-can"}>
            <AppContext.Provider value={{isAuthenticated, userHasAuthenticated}}>
                <Router>
                    <HeaderSection type={isAuthenticated}/>
                    <BaseRouter></BaseRouter>
                    <Switch>
                        <Route path={`/${activeLanguage}/payment-success`} component={Payment}/>
                    </Switch>
                </Router>
                <LoadingIcon/>
            </AppContext.Provider>
        </div>
    );
}

export default App;

