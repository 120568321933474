import React from "react";


export default function ListComponent({ onClick, imageSrc, altText, buttonText }){
    return (
        <li className="nav-item dash-icon-fixer" onClick={onClick}>
            <p className="shape rounded-circle mx-3 mb-0">
                <img className="addIcon" src={imageSrc} alt={altText}></img>
            </p>
            <h6 className="mt-2 mb-0 text-center font8">{buttonText}</h6>
        </li>
    );
};
