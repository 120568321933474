import React, {useEffect, useRef, useState} from "react";
import "../Login/Login.css";
import "./Trashie.css";
import {AskMoreInfoUrl, ComplaintDetailUrl} from "../Routes/ApiEndPoints";
import {APICALL as AXIOS} from "../Services/AxiosServices";
import LocationIcon from "../commonstyle/images/Locationorange.png"

import MiniMap from "../HomeLayout/SingleMap"
import AssignFixer from "../Fixer/AssignFixer";
import UpdateStatus from "../Fixer/UpdateStatus";
import ModalPopup from "../Popup/ModalPopup";
import ProfileImg from "../Trashies/profile.png";
import CommonPopup from "../Services/CommonPopup";
import EditIcon from "../commonstyle/images/Edit.png";
import AddIcon from "../commonstyle/images/Add.png"
import StatusIcon from "../commonstyle/images/Changestatus.png"
import {useReactToPrint} from "react-to-print";
import TrashDetailsToPrint from "./TrashDetailsToPrint";
import FullScreenImage from "../Services/FullScreenImage";
import {useHistory} from 'react-router-dom';
import {t} from '../Translation/Translation';
import BackButton from '../commonstyle/images/back_button.png'
import "./bar_style.css"
import AiModelRequestData from "./AiModelRequestData";

export default function TrashDetail(props) {
    const [statusupdate, setstatusupdate] = useState(false);
    const [complaintId, setComplaintId] = useState();
    const [complaintdata, setComplaintData] = useState();
    const [assigneduserdata, setAssignedUserData] = useState();
    const [assigneduserid, setAssignedUserId] = useState();
    const [createduserdata, setCreatedUserData] = useState({});

    const [aimodeldataid, setAiModelDataId] = useState();
    const [aimodeldetails, setAiModelDetails] = useState({});


    const [task, setTask] = useState("Assign task");
    const role = localStorage.getItem('role')
    const [taskstatus, setTaskStatus] = useState(false);
    const [popupstate, setPopupstate] = useState(false);
    const [locations, setLocations] = useState('None');
    const [center, setCenter] = useState('None');
    const [message, setMessage] = useState("");
    const [info, setInfo] = useState("");
    const [showpopup, setShowpopup] = useState(false);
    const componentRef = useRef();
    const [showupdatepopup, setShowupdatepopup] = useState(false);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });
    const [fullscreenState, setfullscreenState] = useState(false);
    const [imageSrc, setimageSrc] = useState("");


    const history = useHistory();
    useEffect(() => {
        DisableFullScreenImage()
        setShowpopup(false)
        let complaint_id = props.match.params.id
        setComplaintId(complaint_id)
        //Api call to get complaint details
        let data = {
            "complaint_id": complaint_id,
        }
        AXIOS.service(ComplaintDetailUrl, "POST", data)
            .then(result => {
                setAiModelDataId(result.ai_data_id)
                setComplaintData(result.complaint_details)
                setAssignedUserData(result.Assigned_user)
                setAssignedUserId(result.assigned_user_id)
                setCreatedUserData(result.created_user)
                //console.log(result.complaint_details.complaint_id)
                let lat = parseFloat(result.complaint_details[0]['latitude'])
                let long = parseFloat(result.complaint_details[0]['longitude'])
                setLocations({'latitude': lat, 'longitude': long})
                setCenter({'lat': lat, 'lng': long})
                if (result.Assigned_status === true) {
                    setTask("Change status")
                    setTaskStatus(true)
                }

            })
            .catch(error => {
                setMessage(t("Error loading details"))
            })
    }, [props, statusupdate]);
    const AiModelDetailsUrl = '/api/get-ai-model-details/';
    useEffect(() => {
        AXIOS.service(AiModelDetailsUrl, "POST", {
            ai_model_data_id: aimodeldataid
        })
            .then(response => {
                setAiModelDetails(response)
            })
            .catch(error => {
                console.error("Error in fetching data:", error);
            });
    }, [aimodeldataid]);


    //Close popup functions
    const closePopup = () => {
        setShowpopup(false)
        setShowupdatepopup(false)
        // window.location.reload(false)
    }

    const closePopupState = () => {
        setPopupstate(false)
    }

    // Api call to send mail to trashap user for more info
    const AskMoreInfo = (val, subject, email) => {
        let profileId = localStorage.getItem('id')
        let data = {
            'data': val,
            'subject': subject,
            'complaint_id': complaintId,
            'profile_id': profileId,
            'email': email
        }
        AXIOS.service(AskMoreInfoUrl, "POST", data)
            .then(result => {
                setMessage('')
            })
            .catch(error => {
                setMessage('')
            })

    }

    //Function to get more information about complaint created user
    const MoreInfo = () => {
        let infos = {
            'email': createduserdata.email,
            'phone_number': createduserdata.phone_number,
            'gender': createduserdata.gender,
            'location': createduserdata.location
        }
        setInfo(infos)
        setPopupstate(true)
    }

    //enable and disable full screen image functions
    function EnableFullScreenImage(ImageData) {
        setimageSrc(ImageData);
        setfullscreenState(true);
    }

    function DisableFullScreenImage() {
        setimageSrc("");
        setfullscreenState(false);
    }

    function pageUpdate() {
        setstatusupdate(!statusupdate)
    }

    return (
        <section className="content">
            <div className="container-fluid main-box">
                <div className="row title-color pl-0 mt-0 font-weight-bold">
                    <div className="col-md-12 col-12">
                        <img className="backbutton float-left cursor" src={BackButton} onClick={history.goBack}
                             title={t('Click to go back')} alt={"back button"}></img>
                        <h3 className="detailpage-title ml-5 font2">{t('Trashie')}</h3>
                    </div>
                </div>
                <div className=" col-md-12 col-12 bg-white shadow detail-box pb-3 pt-4">
                    {complaintdata !== undefined && Object.keys(complaintdata).map((key) => {
                        return (
                            <div className="container-fluid padd-none pr-0 pb-3">
                                <div className="col-md-12 col-12 row margin-none padd-none mr-0 pr-0">
                                    <div className="col-md-6 col-12 row">
                                        <h3 className="First-text padd-none font3">{complaintdata[key]['title']}</h3>
                                        <p className="date font8">{t('Uploaded on')}: {complaintdata[key]['date'].split('T').slice(0, -1).join("T").split("-").reverse().join("-")}</p>
                                    </div>
                                    <div
                                        className="col-md-6 col-12 padd-none margin-none row justify-content-end detail-right align-left pr-0 mr-0">
                                        <h5 className="mr-3 row status-part text-capitalize font3"><p
                                            className={complaintdata[key]['status'] === 'open' ? "bg-warning status-color" :
                                                complaintdata[key]['status'] === 'in progress' ? "in-progress status-color" :
                                                    complaintdata[key]['status'] === 'fixed' ? "fixed status-color" :
                                                        complaintdata[key]['status'] === 'closed' ? "closed status-color" :
                                                            complaintdata[key]['status'] === 'to approve' ? "bg-primary status-color" :
                                                                complaintdata[key]['status'] === 'approved' ? "approved status-color" :
                                                                    complaintdata[key]['status'] === 'reject' ? "bg-danger status-color" :
                                                                        "bg-secondary status-color"}></p>
                                            {(localStorage.getItem(complaintdata[key]['status']) ? t(localStorage.getItem(complaintdata[key]['status'])) : t(complaintdata[key]['status']))}
                                        </h5>
                                        <p className="btn head-sect btn-sm ml-4 margin-left-none task small-device font-weight-bold cursor font8"
                                           onClick={() => setShowpopup(true)}>{t(task)}{taskstatus ?
                                            <img className="info-icon pl-1 mb-1" src={StatusIcon} alt="icon"/> :
                                            <img className="info-icon pl-1 mb-1" src={AddIcon} alt="icon"/>}</p>
                                        {taskstatus && role !== 'fixer' &&
                                            <p className="btn head-sect btn-sm ml-4 small-device task font-weight-bold cursor font8 right-space"
                                               onClick={() => setShowupdatepopup(true)}>{t('Edit fixer')} <img
                                                className="info-icon pl-1 mb-1" src={EditIcon} alt="icon"/></p>}
                                    </div>
                                </div>
                                {message.length > 0 && (
                                    <CommonPopup show={true}
                                                 onConfirm={(data, subject, email) => AskMoreInfo(data, subject, email)}
                                                 onHide={(data) => setMessage('')}
                                                 size={"lg"}
                                                 title={"Compose mail"}
                                                 body={message}
                                                 type={'mail'}
                                                 status={true}
                                                 email={createduserdata.email}
                                    />
                                )}
                                {fullscreenState && <FullScreenImage imageSrc={imageSrc}
                                                                     DisableFullScreenImage={DisableFullScreenImage}/>}
                                <div className="col-md-12 col-12 row pr-0 mr-0 margin-none padd-none">
                                    <div
                                        className="col-md-6 col-12 pl-0 pr-3 padd-none border-right border-none float-left">
                                        <img className="trash-image padd-none mb-4 cursor"
                                             src={complaintdata[key]['image']}
                                             onClick={() => EnableFullScreenImage(complaintdata[key]['image'])}
                                             alt="trash"></img>
                                        <h5 className="padd-none font8">{t('Description')}</h5>
                                        <p className="padd-none First-text font8">{complaintdata[key]['description'] === 'No description' ? (t('No description')) : complaintdata[key]['description']}</p>

                                        <AiModelRequestData ai_data={aimodeldetails} />

                                        {assigneduserdata != null && <div className="col-md-12 mt-4 col-12 p-0 m-0 row">
                                            <div className="col-md-5 col-12 px-0 float-left First-text"><h5
                                                className="font8">{t('Assigned Fixer')}</h5><p
                                                className="font8">{assigneduserdata}</p></div>
                                            <div className="col-md-7 col-12 row m-0 p-0 justify-content-end">
                                                <p className="btn head-sect btn-sm cursor ml-0 info font-weight-bold float-right font8"
                                                   onClick={() => setMessage('more info')}>{t('Ask for more info')}</p>
                                                <div>
                                                    <p className="btn head-sect btn-sm cursor ml-2 info font-weight-bold float-right font8"
                                                       onClick={handlePrint}>{t('Print')}</p>
                                                    <div style={{display: "none"}}>
                                                        <TrashDetailsToPrint ref={componentRef}
                                                                             data={complaintdata[key]}
                                                                             user={createduserdata}
                                                                            ai_data={aimodeldetails}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {assigneduserdata === null &&
                                            <div className="col-md-12 col-12 mt-4 p-0 row m-0">
                                                <div className="col-md-5 col-0"></div>
                                                <div className="col-md-7 col-12 padd-none pr-0">
                                                    <div>
                                                        <p className="btn head-sect btn-sm cursor ml-2 info font-weight-bold float-right font8"
                                                           onClick={handlePrint}>{t('Print')}</p>
                                                        <div style={{display: "none"}}>
                                                            <TrashDetailsToPrint ref={componentRef}
                                                                                 data={complaintdata[key]}
                                                                                 user={createduserdata}
                                                                                 ai_data={aimodeldetails}
                                                            />
                                                        </div>
                                                    </div>
                                                    <p className="btn head-sect btn-sm cursor ml-0 info font-weight-bold float-right font8"
                                                       onClick={() => setMessage('more info')}>{t('Ask for more info')}</p>
                                                </div>
                                            </div>}

                                    </div>

                                    {showpopup === true && taskstatus === false &&
                                        <AssignFixer complaint={complaintId} onHide={closePopup}
                                                     pageUpdate={pageUpdate}/>}
                                    {showupdatepopup === true &&
                                        <AssignFixer complaint={complaintId} fixer={assigneduserdata}
                                                     fixer_id={assigneduserid} priority={complaintdata[key]['priority']}
                                                     instruction={complaintdata[key]['instruction']} onHide={closePopup}
                                                     pageUpdate={pageUpdate}/>}
                                    {showpopup === true && taskstatus === true &&
                                        <UpdateStatus complaint={complaintId} status={complaintdata[key]['status']}
                                                      onHide={closePopup} pageUpdate={pageUpdate}/>}
                                    {popupstate === true && <ModalPopup info={info} onHide={closePopupState}/>}

                                    <div className="col-md-6 col-12 padd-none margin-none pl-3 pr-0 float-right">
                                        {locations !== 'None' && center !== 'None' &&
                                            <MiniMap className="col-md-6" lats={locations.latitude}
                                                     long={locations.longitude}
                                                     mapaddress={complaintdata[key]['location']}/>}
                                        <div className="col-md-12 col-12 mt-4 mx-0 px-0 row">
                                            <div className="col-md-4 col-12 pl-0">
                                                <h5 className="pl-2 padd-none font8">{t('Address')}</h5>
                                                <p className="row pl-3"><img
                                                    className="locationImage mr-2 pl-1 padd-none" src={LocationIcon}
                                                    alt="location"/><p
                                                    className="mt-0 mb-0 font8">{complaintdata[key]['location'].split(',').slice(0, -1).join(",")},<br/> {complaintdata[key]['location'].split(',').pop()}
                                                </p></p>
                                            </div>
                                            <div className="col-md-8 col-12 ml-0 px-0 row">
                                                {/* {assigneduserdata !== null && <div className=" pr-0 mr-2 pl-1"><h5 className="mt-0">Priority</h5><p className="pl-1 text-capitalize">{complaintdata[key]['priority']}</p></div>} */}
                                                {createduserdata !== undefined &&
                                                    <div className="col-md-9 col-8 pl-3 pr-0 padd-none"><h5
                                                        className="mt-0 font8">{t('Trashie taken by')}</h5>
                                                        <div className="row mt-3 ml-1"><img
                                                            className="profile-image mr-3"
                                                            src={createduserdata.profile_image ? createduserdata.profile_image : ProfileImg}
                                                            alt="profile"></img>
                                                            <p className="mt-0 First-text font8">{createduserdata.name}
                                                                <br/><p
                                                                    className="mt-3 font8">{createduserdata.gamification}</p>
                                                            </p>
                                                        </div>
                                                    </div>}
                                                {createduserdata !== undefined &&
                                                    <div className="col-md-3 col-4 p-0 cursor flex justify-content-end"
                                                         onClick={() => MoreInfo()}><p
                                                        className="head-sect btn btn-sm font-weight-bold w-100 font8">{t('Person info')}</p>
                                                    </div>}
                                            </div>
                                        </div>
                                        {assigneduserdata != null && <div className="col-md-12 col-12 row p-0 mt-3 m-0">
                                            <div className="col-md-4 col-12 padd-none"><h5
                                                className="font8">{t('Priority')}</h5><p
                                                className="First-text font8">{complaintdata[key]['priority']}</p></div>
                                            <div className="col-md-8 col-12 pr-0 pl-3 padd-none"><h5
                                                className="font8">{t('Instructions')}</h5><p
                                                className="First-text font8">{complaintdata[key]['instruction']}</p>
                                            </div>
                                        </div>}

                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
}
