import React, { useState, useEffect } from "react";
import './header.css';
import { useAppContext } from "../Routes/contextLib";
import TrashapLogo from '../commonstyle/images/Trashaplogo.png';
import OverAllIcon from "../commonstyle/images/Overall.png";
import DashboardIcon from "../commonstyle/images/Dashboard.png";
import AccountIcon from "../commonstyle/images/Myaccount.png";
import LogoutIcon from "../commonstyle/images/Signout.png";
import { switchLanguage, getUsersActiveLanguage } from '../Routes/ApiEndPoints';
import { APICALL as AXIOS } from "../Services/AxiosServices";
import { t } from '../Translation/Translation';
import CommonPopup from "../Services/CommonPopup";
import {Button, Modal} from "react-bootstrap";

export default function HeaderSection(type) {

    const { isAuthenticated, userHasAuthenticated } = useAppContext();
    const [activeStatus, setActiveStatus] = useState({});
    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);


    // For now these are the only languages used, so we use this list but if you want to add languages on admin panel you need to change this.
    const supportedLanguages = [
        { code: 'en', name: 'EN' },
        { code: 'nl', name: 'NL' },
        { code: 'fr', name: 'FR' },
    ]

    useEffect(() => {
        const fetchActiveLanguage = async () => {
            if (isAuthenticated) {
                try {
                    const result = await AXIOS.service(getUsersActiveLanguage, "POST", { 'user_id': localStorage.getItem("id") });
                    localStorage.setItem('language', result.active_language);

                    const newActiveStatus = {};
                    supportedLanguages.forEach(lang => {
                        newActiveStatus[lang.code] = lang.code === result.active_language ? 'active' : 'inactive';
                    });

                    setActiveStatus(newActiveStatus);

                    if (window.location.pathname === "/") {
                        window.location.replace(result.active_language);
                    }
                } catch (error) {
                    // Handle error
                    console.error(error);
                }
            }
        };

        fetchActiveLanguage();
    }, [isAuthenticated]);

    const handleLanguageChange = (val) => {
        localStorage.setItem('language', val);

        const newActiveStatus = {};
        supportedLanguages.forEach(lang => {
            newActiveStatus[lang.code] = lang.code === val ? 'active' : 'inactive';
        });

        setActiveStatus(newActiveStatus);

        let path= window.location.pathname;
        // let path2= window.location.pathname;
        // let temp = path2.replace('(\\/nl\\/?)', 'LANG');
        // console.log('LANG');
        // console.log(temp);
        // console.log(path2);


        // Gets the remaining url from the second / so with /en/trashdetail/1 it would get trashdetail/1, this allows us to change languages and stay on the same tab.
        const remainingPath = path.substring(path.indexOf('/', path.indexOf('/') + 1))
        let replacedPath = path.endsWith('/') ? `/${val}` : `/${val}${remainingPath}`;

        window.location.replace(replacedPath);

        const data = {
            'active_language': val,
            'user_id': localStorage.getItem('id')
        };

        AXIOS.service(switchLanguage, "PUT", data)
            .then(result => {
                // console.log(result)
            })
            .catch(error => {
                // console.log(error)
            });
    };

    const handleLogoutConfirmation = () => {
        setShowLogoutConfirmation(true);
    };

    const confirmLogout = () => {
        // Perform logout actions here
        userHasAuthenticated(false);
        localStorage.clear();
        setShowLogoutConfirmation(false)
        window.location.replace('/')
    };


    return (
        <section>
            <div className="navbar head-sect p-0 font-weight-bold">
                {!isAuthenticated && (
                    <nav className="navbar navbar-expand-sm align-self-start text-white pb-0">
                        <img className="trashap-logo" src={TrashapLogo} alt="trashap logo"/>
                    </nav>
                )}

                {isAuthenticated === true && (
                    <nav className="col-md-12 col-12 navbar navbar-expand-lg">
                        <a className="navbar-brand py-0" href="/">
                            <img className="trashap-logo mb-0" src={TrashapLogo} alt="trashap logo"/>
                        </a>
                        <button className="navbar-toggler p-0" type="button" data-toggle="collapse"
                                data-target="#collapsibleNavbar">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end dropdown-mobile"
                             id="collapsibleNavbar">
                            <ul className="navbar-nav navbar-left left-nav-space">
                                {/* Generate language links dynamically */}
                                <li className="nav-item mr-3 dropdown-nav-item language row m-0">
                                    {supportedLanguages.map(lang => (
                                        <React.Fragment key={lang.code}>
                                            <p className={`nav-link lang-space m-0 font-menu ${activeStatus[lang.code]}`}
                                               onClick={() => handleLanguageChange(lang.code)}>
                                                {lang.name}
                                            </p>
                                            {lang.code !== supportedLanguages[supportedLanguages.length - 1].code &&
                                                <p className="mt-1 mb-0 lang-space">|</p>}
                                        </React.Fragment>
                                    ))}
                                </li>
                                {/* Other navigation items */}
                                <li className="nav-item mr-3 dropdown-nav-item">
                                    <a className="nav-link text-white font-menu" href="/">
                                        <img className="nav-icon mr-2" src={DashboardIcon} alt="dashboard"/>
                                        {t('Dashboard')}
                                    </a>
                                </li>
                                {/* Add more navigation items based on your requirements */}
                                <li className="nav-item mr-3 dropdown-nav-item">
                                    <a className="nav-link text-white font-menu" href="/trashlist/all/0">
                                        <img className="nav-icon mr-2" src={OverAllIcon} alt="overall"/>
                                        {t('All trashies')}
                                    </a>
                                </li>
                                <li className="nav-item mr-3 dropdown-nav-item">
                                    <a className="nav-link text-white font-menu" href="/myaccount">
                                        <img className="nav-icon mr-2" src={AccountIcon} alt="account"/>
                                        {t('My account')}
                                    </a>
                                </li>
                                <li className="nav-item mr-3 dropdown-nav-item" style={{ cursor: 'pointer' }}>
                                    <a className="nav-link text-white font-menu" onClick={handleLogoutConfirmation}>
                                        <img className="nav-icon mr-2" src={LogoutIcon} alt="logout"/>
                                        {t('Logout')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>

                )}
            </div>
            <Modal
                show={showLogoutConfirmation}
                onHide={() => setShowLogoutConfirmation(false)}
                size="sm"
                centered
                className="w-80"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="title-color font-head font3">{t('Logout Confirmation')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('Are you sure you want to logout?')}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="head-sect react-button font-para font8" onClick={() => setShowLogoutConfirmation(false)}>
                        {t('Cancel')}
                    </Button>
                    <Button className="head-sect mr-3 react-button font8 font-para" onClick={confirmLogout}>
                        {t('Confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
}
