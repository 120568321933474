import React, { useState, useEffect } from "react";
import "../Login/Login.css";
import "../Trashies/Trashie.css";
import "../HomeLayout/dashboard-style.css";
import { GetFixersUrl, DeleteFixerUrl } from "../Routes/ApiEndPoints";
import { APICALL as AXIOS } from "../Services/AxiosServices";
import Image from "../Trashies/profile.png";
import CommonPopup from "../Services/CommonPopup";
import { useHistory } from "react-router-dom";
import DeleteIcon from "../commonstyle/images/Delete1.png"
import AssignedTasksIcon from "../commonstyle/images/assigned_task1.png"
import {t} from '../Translation/Translation';

export default function ManageFixers() {

    const [dataList, setDataList] = useState();
    const [fixeddatalist, setFixedDataList] = useState();
    const [message, setMessage] = useState("");
    const [fixerId, setFixerId] = useState();
    const [popupState, setPopup] = useState(false);
    const history = useHistory();
    const excludeColumns = ['id', 'image']
    const regex = /(<([^>]+)>)/ig;

    useEffect(() => {
        let customer_id = localStorage.getItem('customer_id')

        //Api call to get all fixers
        let data = {
            "customer_id": customer_id,
            "status": '1',
        }

        AXIOS.service(GetFixersUrl, "POST", data)
            .then(result => {
                if (result !== []) {
                    setDataList(result)
                    setFixedDataList(result)
                } else {
                    setDataList([])
                    setFixedDataList([])
                }
            })
            .catch(() => {
                setDataList(undefined)
            })
    }, []);


    //search elements entered
    const SearchElements = (e) => {
        filterData(e.target.value);
    }

    //filter records by search text
    let filteredData;
    const filterData = (value) => {
        const lowercasedValue = value.toLowerCase().trim();
        if (lowercasedValue === ""){}
        else {
            filteredData = fixeddatalist.filter(item => {
                return Object.keys(item).some(key =>
                    item[key] != null ?
                        excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue) : null
                );
            });
        }
        if (filteredData !== undefined) {
            setDataList(filteredData);
        } else {
            setDataList(fixeddatalist);
        }
    }

    const deleteFixer = (val) => {
        setFixerId(val)
        setPopup(true)
        setMessage(t("Are you sure you want to delete the fixer?"))
    }

    const onConfirm = () => {
        let data = {
            'fixer_id': fixerId
        }
        AXIOS.service(DeleteFixerUrl, "POST", data)
            .then(result => {
                // console.log(result)
            })
            .catch(error => {
                // console.log(error)
            })
        setPopup(false)
        setFixerId("")
        setMessage("")
        window.location.reload(false)
    }

    const onClose = () => {
        setFixerId("")
        setMessage("")
        setPopup(false)
    }

    const AssignedTasks = (val) => {
        history.push('/assignedtasks/' + val)
    }

    return (
        <section className="content">
            <div className="container-fluid mt-2 main-box">
                <div className="row">
                    <div className="col-md-6 title-color font-weight-bold " ><h4 className="font2">{t('Manage Fixers')}</h4></div>
                    <div className="col-md-6"><input type="text" id="myInput" onChange={(e) => SearchElements(e)} placeholder={t("Search based on name..")} /></div>
                </div>

                <div className=" col-md-12 bg-white shadow box pt-4 pb-5">
                    <div className="col-md-12 row margin-none">
                        {popupState === true && (
                            <CommonPopup show={true}
                                onHide={() => onClose()}
                                onConfirm={() => onConfirm()}
                                size={"lg"}
                                title={"Alert"}
                                body={message}
                                status={true}
                            />
                        )}
                        {dataList === undefined && <div className="col-md-12 col-12 text-center mt-3" ><h4 className="text-center title-color"> {t("Loading Fixers")}.</h4> </div>}
                        {dataList !== undefined && Object.keys(dataList).map((key) => {
                            return (
                                <div className="col-md-5 col-lg-3 col-sm-5 col-12 card mt-5 fixer-card bg-white shadow-lg border border-light" key={dataList[key].id}>
                                    <div>
                                        <img className="col-md-12 col-12 image p-0 mt-0" src={dataList[key]['image'] === "" ? Image : dataList[key]['image']} alt="profile"></img>

                                        <h3 className="mt-2 mb-2 font6">{dataList[key]['fixer'] + " " + dataList[key]['last_name']}</h3>
                                        <p className="col-md-12 col-12 font8 mx-0 px-0 row">{t('Email')}: {dataList[key]['email'].length > 35 ? <p className="mb-0 font8">{dataList[key]['email'].replace(regex, '').substring(0, 32)} <br></br> {dataList[key]['email'].replace(regex, '').substring(32, 50)}</p> : dataList[key]['email']}</p>
                                        <p className="col-md-12 col-12 font8 mx-0 px-0 row">{t('Phone number')}: {(dataList[key]['mobile'])}</p>
                                    </div>
                                    <div className="row icon cursor">
                                        <img className="ml-1 mr-3 fixerIcon" onClick={() => deleteFixer(dataList[key].id)}  src={DeleteIcon} title={t("Delete fixer")} alt={"delete icon"}/>
                                        <img className="ml-3 mr-2 fixerIcon" onClick={() => AssignedTasks(dataList[key].profile_id)} src={AssignedTasksIcon} title={t("Assigned tasks")}  alt={"Assinged task icon"}/>
                                    </div>
                                </div>
                            );
                        })}
                        {dataList === undefined || dataList.length === 0 && <h3 className="col-12 text-center title-color mt-5" > {t("No Fixers found ")} </h3>}
                    </div>
                </div>
            </div>
        </section>
    );
}
