import React, { useState, useEffect } from "react";
import "../Login/Login.css";
import "./myprofile.css"
import "../Trashies/Trashie.css"
import axios from 'axios';
import { GetAccountDetailsUrl, UploadImageUrl } from "../Routes/ApiEndPoints";
import { APICALL as AXIOS } from "../Services/AxiosServices";
import Image from "../Trashies/profile.png";
import PencilIcon from "../commonstyle/images/camera.png";
import { useHistory } from "react-router-dom";
import CommonPopup from "../Services/CommonPopup";
import {t} from '../Translation/Translation';



export default function Account() {

    useEffect(() => {
        let profile_id = localStorage.getItem('id')
        //Api call to get account details
        let data = {
            "profile_id": profile_id,
        }
        AXIOS.service(GetAccountDetailsUrl, "POST", data)
            .then(result => {
                if (result['status'] === 200) {
                    setCustomer(result['customer_name'])
                    setMunicipality(result['municipalities'].join())
                    setEmail(result['email'])
                    setFirstname(result['first_name'])
                    setLastname(result['last_name'])
                    setMobile(result['phone_number'])
                    setProfileImage(result['profile_image'])
                } else {
                    setMessage(t(result['message']))
                }
            })
            .catch(error => {
                setMessage(t('Error loading details'))
            })
    }, []);

    const [firstname, setFirstname] = useState("None");
    const [lastname, setLastname] = useState("None");
    const [email, setEmail] = useState("None");
    const [mobile, setMobile] = useState("None");
    const [message, setMessage] = useState("");
    const [customer, setCustomer] = useState("None");
    const [municipality, setMunicipality] = useState("None");
    const [profileImage, setProfileImage] = useState("");
    const [image, setImage] = useState("None");
    const history = useHistory();

    var formData = new FormData();

    //Display image after selecting file
    const loadFile = (event) => {
        event.preventDefault();
        let files = event.target.files

        setImage(files[0])
        var output = document.getElementById('temp');
        output.src = URL.createObjectURL(event.target.files[0]);
        output.onload = function () {
            URL.revokeObjectURL(output.src)
        }
    }

    //Function to call update account details api
    function handleSubmit(e) {
        e.preventDefault();

        formData.append('image', image);
        formData.append('email', email);
        formData.append('first_name', firstname);
        formData.append('last_name', lastname);
        formData.append('mobile', mobile);
        formData.append('profile_id', localStorage.getItem('id'))

        axios.post(UploadImageUrl, formData, { headers: { "Authorization": localStorage.getItem('token'), "content-type": "application/form-data" } })
            .then(result => {
                if (result.data['status'] === 200) {
                    setMessage(result.data['Response']);
                    // history.push('/');

                } else {
                    setMessage(result.data['Response']);
                }
            })
            .catch(error => {
                setMessage(t("Error uploading image"));
            })
    }


    return (
        <section className="content">
            <div className="container">
                <div className="row justify-content-md-center mx-0 rounded mt-2 pb-2 ">
                    <div className="col-md-8 col-lg-6 col-12 col-sm-12 py-2 my-2 bg-white shadow">
                        <div className="col-md-12 my-1 title">
                            <h3 className="font3">{t('My account')}</h3>
                        </div>
                        {message.length > 0 && (
                            <CommonPopup show={true}
                                onHide={() => setMessage("")}
                                size={"lg"}
                                title={"Alert"}
                                body={message}
                                status={false}
                                cancelButton={"Ok"}
                            />
                        )}

                        <form onSubmit={handleSubmit}>
                            <div className="col-md-12 title">
                                <img className="image-shape rounded-circle" id="temp" src={profileImage === null ? Image : profileImage} alt="profile"></img>
                            </div>
                            <div className="col-md-12 camera title">
                                <label for="input-file" className="mb-0">
                                    <img className="iconstyle mt-0" src={PencilIcon} alt="edit" />
                                </label>
                                <input id="input-file" type="file" className="d-none" onChange={(e) => loadFile(e)}></input>
                            </div>
                            <div className="col-md-12 form-inline mb-3">
                                <label className="col-md-4 col-sm-4 pl-0 justify-content-start font8">{t('First name')}</label>
                                <input type="text" className="col-md-8 col-sm-8 form-control bg-light font8" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                            </div>
                            <div className="col-md-12 form-inline mb-3">
                                <label className="col-md-4 col-sm-4 pl-0 justify-content-start font8">{t('Last name')}</label>
                                <input type="text" className="col-md-8 col-sm-8 form-control bg-light font8" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                            </div>
                            <div className="col-md-12 form-inline mb-3">
                                <label className="col-md-4 col-sm-4 pl-0 justify-content-start font8">{t('Email')}</label>
                                <input type="text" className="col-md-8 col-sm-8 form-control bg-light font8" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="col-md-12 form-inline mb-3">
                                <label className="col-md-4 col-sm-4 pl-0 justify-content-start font8">{t('Customer')}</label>
                                <input type="text" className="col-md-8 col-sm-8 form-control font8" value={customer} disabled />
                            </div>
                            <div className="col-md-12 form-inline mb-3">
                                <label className="col-md-4 col-sm-4 pl-0 justify-content-start font8">{t('Cities')}</label>
                                <input type="text" className="col-md-8 col-sm-8 form-control font8" value={municipality} disabled />
                            </div>
                            <div className="col-md-12 form-inline mb-3">
                                <label className="col-md-4 col-sm-4 pl-0 justify-content-start font8">{t('Phone number')}</label>
                                <input type="text" className="col-md-8 col-sm-8 form-control bg-light font8" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                            </div>

                            <div className="col-md-12 mt-3 ">
                                <button type="submit" className="btn btn-sm mybtn font-weight-bold font-para text-white float-right font8" > {t('Save')} </button>
                                <button type="cancel" className="btn btn-sm mr-3 mybtn font-weight-bold font-para text-white float-right font8" onClick={() => history.push('/')} > {t('Cancel')} </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}
