// dataParsers.js
import React from "react";
import recycleImage from "../commonstyle/images/recycleImage.png"
import trashcanImage from "../commonstyle/images/trashcanImage.png"


export function parseGoogleVisionData(jsonData) {
    if (jsonData && jsonData.data && jsonData.data.responses.length > 0) {
        const labelAnnotations = jsonData.data.responses[0].labelAnnotations;
        const sortedData = labelAnnotations.sort((a, b) => b.score - a.score);

        return (
            <>
                <h5 className="padd-none font8 mt-4">{'Google Vision Details'}</h5>
                <div className="bar-chart-container">
                    {sortedData.map(({ score, description }, index) => (
                        <div key={index} className="bar-container">
                            <div className="bar">
                                <div className="filled-bar" style={{ width: `${score * 100}%` }}>
                                    <span className="bar-text">
                                        {Math.round(score * 100)}%
                                    </span>
                                </div>
                            </div>
                            <div className="description">{description}</div>
                        </div>
                    ))}
                </div>
            </>
        );
    }
    return null;
}

export function parseGeminiData(data) {
    if (data && data.data && data.data.Count && Object.keys(data.data.Count).length > 0) {
        const count = data.data.Count;
        const probability = data.data.Probability;
        const recyclability = data.data.Recyclability;
        const environmentalImpact = data.data["Environmental Impact"];
        const brands = data.data.Brands;

        const parsedData = Object.keys(count).map((key) => ({
            item: key,
            count: count[key],
            probability: parseFloat(probability[key]),
            recyclability: recyclability[key],
            environmentalImpact: environmentalImpact
        }));

        const sortedData = parsedData.sort((a, b) => b.probability - a.probability);

        return (
            <>
                <h5 className="padd-none font5 mt-4">{'Google Gemini Details'}</h5>
                <div className="bar-chart-container">
                    {sortedData.map(({item, probability, recyclability}, index) => (
                        <div key={index} className="bar-container">
                            <div className="bar">
                                <div className="filled-bar" style={{width: `${probability}%`}}>
                                    <span className="bar-text">
                                        {probability}%
                                    </span>
                                </div>
                            </div>
                            <div className="description">{item}</div>
                            {/*<div className="recyclability">*/}
                            {/*    {recyclability === "yes" ? (*/}
                            {/*        <img src={recycleImage} alt="Recyclable" className="shape2"/>*/}
                            {/*    ) : (*/}
                            {/*        <img src={trashcanImage} alt="Not Recyclable" className="shape2"/>*/}
                            {/*    )}*/}
                            {/*</div>*/}
                        </div>
                    ))}
                </div>
                {Object.keys(brands).length > 0 && (
                    <div className="brands-container">
                        <h6 className="padd-none font5 mt-4">{'Brands'}</h6>
                        {Object.entries(brands).map(([brand, count], index) => (
                            <div key={index} className="brand-item">
                                <span className="brand-name font-bold">{brand}</span>
                                <span className="brand-count"> ({count} times)</span>
                            </div>
                        ))}
                    </div>
                )}
                {/*<div className="environmental-impact mt-4 ">*/}
                {/*    <span>Environmental Impact: {environmentalImpact}</span>*/}
                {/*</div>*/}
            </>
        );
    }
    return null;
}
