import React, { useState, useEffect } from "react";
import "../Login/Login.css";
import "../Trashies/Trashie.css";
import { GetComplaintsUrl } from "../Routes/ApiEndPoints";
import { APICALL as AXIOS } from "../Services/AxiosServices";
import { useHistory } from "react-router-dom";
import LocationIcon from "../commonstyle/images/Locationwhite.png"
import UpdateStatus from "../Fixer/UpdateStatus";
import StatusIcon from "../commonstyle/images/Changestatus.png"
import { t } from '../Translation/Translation';
import BackButton from '../commonstyle/images/back_button.png'
import InfiniteScroll from "react-infinite-scroll-component";

export default function AssignedTasks(props) {
    const [statusupdate, setstatusupdate] = useState(false);
    useEffect(() => {
        let profile_id = props.match.params.id

        //Api call to get all fixers
        let data = {
            "profile_id": profile_id,
            "app_type": 'trashap',
            "offset": 0,
            "status": 'all',
            "monthId": 0,
            "searchVal": ''
        }

        AXIOS.service(GetComplaintsUrl, "POST", data)
            .then(result => {
                if (result.data.length !== 0) {
                    setDataList(result.data)
                    setFixedDataList(result.data)
                    let length = result.data.length
                    setoffset(result.data[length - 1].id)
                } else {
                    setDataList([])
                    setFixedDataList([])
                }
            })
            .catch(() => {
                setDataList(undefined)
            })
    }, [statusupdate]);

    const [dataList, setDataList] = useState();

    //fixeddatalist is never used but the set is, can be removed?
    const [fixeddatalist, setFixedDataList] = useState();

    const [complaintId, setComplaint] = useState();
    const [Status, setStatus] = useState();
    const [showstatuspopup, setshowstatuspopup] = useState(false);

    //Never used, can be removed?
    const excludeColumns = ['id', 'image']

    const [offset, setoffset] = useState(0);
    const [hasMore, sethasMore] = useState(true);
    const history = useHistory();
    const regex = /(<([^>]+)>)/ig;

    const FetchMoreData = (last_id, serachVal) => {
        let profile_id = props.match.params.id
        let data = {
            "profile_id": profile_id,
            "app_type": "trashap",
            "offset": last_id,
            "status": 'all',
            "monthId": 0,
            "searchVal": serachVal
        }
        AXIOS.service(GetComplaintsUrl, "POST", data)
            .then(result => {
                if (last_id !== 0) {
                    if (result.data.length !== 0) {
                        let AddedData = dataList.concat(result.data)
                        var finaldata = [...new Map(AddedData.map(o => [o.id, o])).values()]
                        setDataList(finaldata)
                        sethasMore(true)
                        let length = result.data.length
                        setoffset(result.data[length - 1].id)
                    }
                    else {
                        sethasMore(false)
                    }
                } else {
                    if (result.data.length !== 0) {
                        sethasMore(true)
                        setDataList(result.data)
                        let length = result.data.length
                        setoffset(result.data[length - 1].id)
                    } else {
                        setDataList([])
                        sethasMore(false)
                    }
                }
            })
            .catch(() => {
                setDataList(undefined)
                sethasMore(false)
            })
    }

    //search elements entered
    const SearchElements = (e) => {
        FetchMoreData(0, e.target.value);
    }

    //filter records by search text
    // let filteredData;
    // const filterData = (value) => {
    //     const lowercasedValue = value.toLowerCase().trim();
    //     if (lowercasedValue === "");
    //     else {
    //         filteredData = fixeddatalist.filter(item => {
    //             return Object.keys(item).some(key =>
    //                 item[key] != null ?
    //                     excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue) : null
    //             );
    //         });
    //     }
    //     if (filteredData !== undefined) {
    //         setDataList(filteredData);
    //     } else {
    //         setDataList(fixeddatalist);
    //     }
    // }

    //Function to go to detail page with complaint id
    const GoToDetailPage = (val) => {
        history.push('/trashdetail/' + val)
    }

    const ConfirmStatusPopup = (val) => {
        setComplaint(val['id'])
        setStatus(val['status'])
        setshowstatuspopup(true)
    }

    const closePopup = () => {
        setshowstatuspopup(false)
        // window.location.reload(false)
    }
    function pageUpdate() {
        setstatusupdate(!statusupdate)
    }

    //function to get location map
    const getLocation = () => {
        navigator.geolocation.getCurrentPosition(
            getcoordinates
        );
    }
    //Getting location in map based on given lat and long
    function getcoordinates(position) {
        Object.keys(dataList).map((key) => {
            if ((navigator.platform.indexOf("iPhone") !== -1)
                || (navigator.platform.indexOf("iPod") !== -1)
                || (navigator.platform.indexOf("iPad") !== -1)) {
                let url = 'https://www.google.com/maps/dir/' + position.coords.latitude + ',' + position.coords.longitude + '/' + dataList[key].latitude + ',' + dataList[key].longitude;
                window.location.assign(url, "_blank");
            }
            else {
                let urls = 'https://www.google.com/maps/dir/' + position.coords.latitude + ',' + position.coords.longitude + '/' + dataList[key].latitude + ',' + dataList[key].longitude;
                window.open(urls);
            }
        })
    }

    return (
        <section className="content">
            <div className="container-fluid main-box">
                <div className="row">
                    <div className="col-md-6 title-color font-weight-bold "><img className="backbutton float-left cursor" src={BackButton} onClick={history.goBack} title={t('Click to go back')} alt={"Back button"}></img><h4 className="font2 ml-5">{t('Assigned tasks')}</h4></div>
                    <div className="col-md-6"><input type="text" id="myInput" onChange={(e) => SearchElements(e)} placeholder={t("Search based on title..")} /></div>
                </div>

                <div className=" col-md-12 col-12 bg-white shadow box pt-4 pb-5">
                    {showstatuspopup === true && <UpdateStatus complaint={complaintId} status={Status} onHide={closePopup} pageUpdate={pageUpdate} />}
                    {dataList !== undefined &&
                        <InfiniteScroll
                            dataLength={dataList !== undefined ? dataList.length : 0}
                            next={() => FetchMoreData(offset, '')}
                            hasMore={hasMore}
                        // loader={
                        //     <h5 className="textcolor1 text-center">{t("Loading")}.</h5>
                        // }
                        // scrollableTarget="scrollableDiv"
                        >
                            <div className="col-md-12 col-12 margin-none row">
                                {dataList === undefined && <div className="col-12 text-center mt-3" ><h4 className="text-center title-color"> {t("Loading Trashies")}.</h4> </div>}
                                {dataList !== undefined && Object.keys(dataList).map((key) => {
                                    return (
                                        <div className="col-md-5 col-lg-3 col-sm-5 col-12 pl-2 pr-2 pb-0 card fixer-card mt-5 bg-white shadow-lg border border-light" key={dataList[key].id}>
                                            <div className="cursor" onClick={() => GoToDetailPage(dataList[key].id)}>
                                                <img className="image col-md-12 col-12 p-0" src={dataList[key]['image']} alt="trash image"></img>

                                                <h3 className="mt-2 First-text font6">{dataList[key]['title'] !== undefined ? (dataList[key]['title'].length > 20 ?
                                                    dataList[key]['title'].replace(regex, '').substring(0, 18) + '..' : dataList[key]['title']) : null}</h3>
                                                <p className="small First-text font8">{t('Reported user')}: {dataList[key]['reported_user'] !== undefined ? (dataList[key]['reported_user'].length > 35 ?
                                                    dataList[key]['reported_user'].replace(regex, '').substring(0, 32) + '...' : dataList[key]['reported_user']) : null}</p>
                                                <p className="small font8 ">{t('Location')}: {dataList[key]['location']}</p>
                                                <p className="small font8">{t('Uploaded on')}: {(dataList[key]['date']).split('T').slice(0, -1).join("T").split("-").reverse().join("-")}</p>

                                            </div>
                                            <div className="col-md-12 col-12 pr-0 pl-0 ml-0 mr-0 mt-2 row">
                                                <div className="col-md-8 col-6 button-width row">
                                                    <p className={dataList[key]['status'] === 'open' ? "bg-warning status-roll mr-1" :
                                                        dataList[key]['status'] === 'in progress' ? "in-progress status-roll mr-1" :
                                                            dataList[key]['status'] === 'fixed' ? "fixed status-roll mr-1" :
                                                                dataList[key]['status'] === 'closed' ? "closed status-roll mr-1" :
                                                                    dataList[key]['status'] === 'to approve' ? "bg-primary status-roll mr-1" :
                                                                        dataList[key]['status'] === 'approved' ? "approved status-roll mr-1" :
                                                                            dataList[key]['status'] === 'reject' ? "bg-danger status-roll mr-1" :
                                                                                "bg-secondary status-roll mr-1"}></p>
                                                    <p className="First-text font8">{t(dataList[key]['status'])}</p>
                                                </div>
                                                <div className="col-md-5 col-6 button-width justify-content-end pr-0 row m-0">
                                                    <p className="button-shape cursor"><img className="m-1 ButtonIcon" onClick={() => getLocation()} src={LocationIcon} title={t("Location")} /></p>
                                                    <p className="button-shape ml-3 cursor"><img className="m-1 ButtonIcon" onClick={() => ConfirmStatusPopup(dataList[key])} src={StatusIcon} title={t("Change status")} /></p>
                                                </div>
                                                {/* <div className="col-md-6 ml-0 row float-right">
                                            <img className="ml-3 delete-hover taskLocation" onClick={() => getLocation()} src={LocationIcon} alt="icon" />
                                            <img className="ml-3 task-hover TaskStatus" onClick={() => ConfirmStatusPopup(dataList[key])} src={StatusIcon} alt="icon" />
                                        </div> */}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </InfiniteScroll>
                    }
                    {dataList === undefined || dataList.length === 0 && <div className="col-md-12 col-12 margin-none row"><h3 className="col-12 text-center title-color mt-5" > {t("No Tasks assigned")} </h3></div>}

                </div>
            </div>
        </section>
    );
}
