import React, {useState, useEffect} from 'react';
import "./bar_style.css"
import {parseGeminiData, parseGoogleVisionData} from "../Services/AiDataParsers";

export default function AiModelRequestData(props) {
    const [aiDataContent, setAiDataContent] = useState(null);

    useEffect(() => {
        if (props.ai_data) {

            if (props.ai_data.ai_model_name === "gemini-pro-vision") {
                setAiDataContent(parseGeminiData(props.ai_data));
            }
            else if (props.ai_data.ai_model_name === "google-vision") {
                setAiDataContent(parseGoogleVisionData(props.ai_data));
            }
        }
    }, [props.ai_data]);


    return (
        <div>
            {aiDataContent}
        </div>
    );
}
