import React, {useState, useEffect} from "react";
import "../Login/Login.css";
import "./Trashie.css";
import {GetComplaintsUrl} from "../Routes/ApiEndPoints";
import {APICALL as AXIOS} from "../Services/AxiosServices";
import {useHistory} from "react-router-dom";
import LocationIcon from "../commonstyle/images/Locationwhite.png"
import AddIcon from "../commonstyle/images/Add.png"
import AssignFixer from "../Fixer/AssignFixer";
import UpdateStatus from "../Fixer/UpdateStatus";
import StatusIcon from "../commonstyle/images/Changestatus.png"
import {t} from '../Translation/Translation';
import InfiniteScroll from "react-infinite-scroll-component";

export default function TrashList(props) {
    const [statusupdate, setstatusupdate] = useState(false);

    const [dataList, setDataList] = useState();
    const [showpopup, setShowpopup] = useState(false);
    const [Status, setStatus] = useState();
    const [showstatuspopup, setshowstatuspopup] = useState(false);
    const role = localStorage.getItem('role')
    const [complaintId, setComplaint] = useState();
    const [fixeddatalist, setFixedDataList] = useState();
    const [newcount, setNewCount] = useState(0);
    const [opencount, setOpenCount] = useState(0);
    const [fixedcount, setFixedCount] = useState(0);
    const [progresscount, setProgressCount] = useState(0);
    const [completecount, setCompleteCount] = useState(0);
    const [rejectcount, setRejectCount] = useState(0);
    const [allcount, setAllCount] = useState(0);
    const history = useHistory();
    const excludeColumns = ["id", "latitude", "longitude", "instruction", "image", "description", "date"];
    const regex = /(<([^>]+)>)/ig;
    const [active_all, SetActiveAll] = useState('active');
    const [tabStatus, settabStatus] = useState(' ');
    const [offset, setoffset] = useState(0);
    const [hasMore, sethasMore] = useState(true);
    const [presentStatus, setPresentStatus] = useState('all')
    // const [keyVal, setkeyVal] = useState('')

    useEffect(() => {
        setShowpopup(false)
        const defaultStatus = props.match.params.status
        setPresentStatus(defaultStatus)
        if (defaultStatus !== 'all') {
            settabStatus('No' + ` ${defaultStatus} ` + 'trashies found');
        } else {
            settabStatus('No trashies found');
        }

        const monthId = props.match.params.id
        let profile_id = localStorage.getItem('id')
        //Api call to get all complaints
        let data = {
            "profile_id": profile_id,
            "app_type": "trashap",
            "offset": 0,
            "status": defaultStatus,
            "monthId": monthId,
            "searchVal": ''
        }
        AXIOS.service(GetComplaintsUrl, "POST", data)
            .then(result => {
                if (result.counts) {
                    SetCount(result.counts)
                }
                if (result.data.length !== 0) {
                    let length = result.data.length
                    setoffset(result.data[length - 1].id)
                    setDataList(result.data)
                    setFixedDataList(result.data)

                } else {
                    setDataList([])
                    setFixedDataList([])
                    sethasMore(false)
                }
            })
            .catch(error => {
                setDataList(undefined)
                sethasMore(false)
            })
    }, [statusupdate]);


    //Function get compliants in different tabs based on status
    const TabChange = (status) => {
        setPresentStatus(status)
        sethasMore(true)
        if (status !== 'all') {
            settabStatus('No' + (` ${status} `) + 'trashies found');
            setPresentStatus(status)
            setoffset(0)
            FetchMoreData(status, 0, '');
            SetActiveAll('')
        } else {

            setDataList(fixeddatalist);
            settabStatus("No trashies found");
        }
    }

    const SetCount = (counts) => {
        setAllCount(counts.all >= 99 ? 99 : counts.all);
        setNewCount(counts.new >= 99 ? 99 : counts.new);
        setOpenCount(counts.open >= 99 ? 99 : counts.open);
        setProgressCount(counts.progress >= 99 ? 99 : counts.progress)
        setFixedCount(counts.fixed >= 99 ? 99 : counts.fixed)
        setCompleteCount(counts.closed >= 99 ? 99 : counts.closed)
        setRejectCount(counts.rejected >= 99 ? 99 : counts.rejected)
    }

    const FetchMoreData = (status, last_id, searchVal) => {
        let data = {
            "profile_id": localStorage.getItem('id'),
            "app_type": "trashap",
            "offset": last_id,
            "status": status,
            "monthId": props.match.params.id,
            "searchVal": searchVal
        }
        AXIOS.service(GetComplaintsUrl, "POST", data)
            .then(result => {
                if (last_id !== 0) {
                    if (result.data.length !== 0) {
                        let dataVal = dataList.concat(result.data)
                        let finaldata = [...new Map(dataVal.map(o => [o.id, o])).values()]
                        setDataList(finaldata)
                        sethasMore(true)
                        SetCount(result.counts)
                        let length = result.data.length
                        setoffset(result.data[length - 1].id)

                    } else {
                        sethasMore(false)
                    }
                } else {
                    if (result.data.length !== 0) {
                        sethasMore(true)

                        SetCount(result.counts)
                        setDataList(result.data)
                        let length = result.data.length
                        setoffset(result.data[length - 1].id)
                    } else {
                        setDataList([])
                        sethasMore(false)
                    }
                }
            })
            .catch(error => {
                setDataList(undefined)
                sethasMore(false)
            })
    }

    //Function to go to detail page with complaint id
    const GoToDetailPage = (val) => {
        history.push('/en/trashdetail/' + val)
    }

    //Function for assign fixer popup
    const ConfirmPopup = (val) => {
        setComplaint(val)
        setShowpopup(true)
    }

    const ConfirmStatusPopup = (val) => {
        setComplaint(val['id'])
        setStatus(val['status'])
        setshowstatuspopup(true)
    }

    const closePopup = (e) => {
        // e.preventDefault()
        setShowpopup(false)
        setshowstatuspopup(false)
    }

    //search elements entered
    const SearchElements = (e) => {
        FetchMoreData(presentStatus, 0, e.target.value);
    }


    //function to get location map
    const getLocation = (dataValue) => {
        navigator.geolocation.getCurrentPosition(
            function getcoordinates(position) {
                if ((navigator.platform.indexOf("iPhone") !== -1)
                    || (navigator.platform.indexOf("iPod") !== -1)
                    || (navigator.platform.indexOf("iPad") !== -1)) {
                    let url = 'https://www.google.com/maps/dir/' + position.coords.latitude + ',' + position.coords.longitude + '/' + dataValue.latitude + ',' + dataValue.longitude;
                    window.location.assign(url, "_blank");
                } else {
                    let urls = 'https://www.google.com/maps/dir/' + position.coords.latitude + ',' + position.coords.longitude + '/' + dataValue.latitude + ',' + dataValue.longitude;
                    window.open(urls);
                }

            }
        );
    }


    //Function to refresh data
    function pageUpdate() {
        setstatusupdate(!statusupdate)
    }

    return (
        <section className="content">
            <div className="container-fluid main-box">
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-12 title-color "><h4
                        className="font2 First-text">{props.match.params.id === '0' ? t('All Trashies') : t(props.match.params.status + ' trashies')}</h4>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12"><input type="text" id="myInput"
                                                                     onChange={(e) => SearchElements(e)}
                                                                     placeholder={t("Search based on title..")}/></div>
                </div>

                <div className=" col-md-12 col-12 bg-white shadow box pt-4 pb-5">
                    {props.match.params.id === '0' &&
                        <div className="col-md-12 row col-12 padd-none margin-none border-bottom ml-1">
                            <ul className="nav nav-tabs nav-justified cursor">
                                <li className="nav-item width-nav"><a
                                    className={"nav-link width-link mb-0 font8 " + active_all} href="#"
                                    onClick={() => TabChange('all')}>{t('All')}<small
                                    className="ml-2 font8 count mb-0 rounded-circle head-sect">{allcount}</small></a>
                                </li>
                                <li className="nav-item width-nav"><a className="nav-link width-link mb-0 font8 "
                                                                      href="#"
                                                                      onClick={() => TabChange('new')}>{t('New')}<small
                                    className="ml-2 font8 count mb-0 rounded-circle head-sect">{newcount}</small></a>
                                </li>
                                <li className="nav-item width-nav"><a className="nav-link width-link mb-0 font8 "
                                                                      href="#"
                                                                      onClick={() => TabChange('open')}>{t('Open')}<small
                                    className="ml-2 font8 count mb-0 rounded-circle head-sect">{opencount}</small></a>
                                </li>
                                <li className="nav-item width-nav"><a className="nav-link width-link mb-0 font8 "
                                                                      href="#"
                                                                      onClick={() => TabChange('in progress')}>{t('In progress')}<small
                                    className="ml-2 font8 count mb-0 rounded-circle head-sect">{progresscount}</small></a>
                                </li>
                                <li className="nav-item width-nav"><a className="nav-link width-link mb-0 font8 "
                                                                      href="#"
                                                                      onClick={() => TabChange('fixed')}>{t('Fixed')}<small
                                    className="ml-2 count mb-0 font8 rounded-circle head-sect">{fixedcount}</small></a>
                                </li>
                                <li className="nav-item width-nav"><a className="nav-link width-link mb-0 font8 "
                                                                      href="#"
                                                                      onClick={() => TabChange('closed')}>{t('Closed')}<small
                                    className="ml-2 font8 count mb-0 rounded-circle head-sect">{completecount}</small></a>
                                </li>
                                <li className="nav-item width-nav"><a className="nav-link width-nav mb-0 font8 "
                                                                      href="#"
                                                                      onClick={() => TabChange('reject')}>{t('Rejected')}<small
                                    className="ml-2 font8 count mb-0 rounded-circle head-sect">{rejectcount}</small></a>
                                </li>
                            </ul>
                        </div>}
                    {showpopup === true &&
                        <AssignFixer complaint={complaintId} onHide={closePopup} pageUpdate={pageUpdate}/>}
                    {showstatuspopup === true &&
                        <UpdateStatus complaint={complaintId} status={Status} onHide={closePopup}
                                      pageUpdate={pageUpdate}/>}
                    {/* <div className="col-md-12 col-12 row m-0 pl-0"> */}
                    {dataList === undefined && <div className="col-12 text-center mt-3">
                        <h4 className="text-center title-color"> {t("Loading Trashies")}.</h4>
                    </div>}

                    {dataList !== undefined &&
                        <InfiniteScroll
                            dataLength={dataList !== undefined ? dataList.length : 0}
                            next={() => FetchMoreData(presentStatus, offset, '')}
                            hasMore={hasMore}
                            // loader={
                            //     <h5 className="textcolor1 text-center">{t("Loading")}.</h5>
                            // }
                            // scrollableTarget="scrollableDiv"
                        >
                            <div className="col-md-12 col-12 row m-0 pl-0">
                                {Object.keys(dataList).map((key) => {
                                    return (
                                        <div
                                            className="col-md-5 col-lg-3 col-sm-5 col-12 px-2 pb-1 card mt-5 bg-white shadow-lg border border-light"
                                            key={dataList[key].id}>
                                            <div className="cursor" onClick={() => GoToDetailPage(dataList[key].id)}>
                                                <img className="col-md-12 col-12 image p-0" src={dataList[key]['image']}
                                                     alt="trash image"></img>
                                                <h3 className="mt-2 font6 First-text">{dataList[key]['title'] !== undefined ? (dataList[key]['title'].length > 20 ? dataList[key]['title'].replace(regex, '').substring(0, 18) + '..' : dataList[key]['title']) : null}</h3>
                                                {props.match.params.id === '0' &&
                                                    <p className={dataList[key]['priority'] !== null ? "bg-success float-right status-color-round rounded-circle mr-1 mb-0" : "bg-secondary float-right status-color-round rounded-circle mr-1 mb-0"}></p>
                                                }
                                                <p className="First-text small font8">{t('Reported user')}: {dataList[key]['reported_user'] !== undefined ? (dataList[key]['reported_user'].length > 35 ? dataList[key]['reported_user'].replace(regex, '').substring(0, 32) + '...' : dataList[key]['reported_user']) : null}</p>
                                                <p className="small font8 ">{t('Uploaded on')}: {(dataList[key]['date']).split('T').slice(0, -1).join("T").split("-").reverse().join("-")}</p>
                                                <p className="small font8 ">{t('Assigned fixer')}: {dataList[key]['fixer_name']}</p>
                                            </div>
                                            <div
                                                className="col-md-12 col-12 px-0 mx-0 bottom-part-trashie row cursor flex-small-none">
                                                {props.match.params.id === '0' ?
                                                    <div className="col-md-6  p-0">{dataList[key]['priority'] !== null ?
                                                        <p className="small row ml-0 font8">{t('Assigned')}</p> :
                                                        <p className="small row ml-0 font8">{t('Unassigned')}</p>}
                                                    </div> :
                                                    <div className="col-md-6 pl-0 m-0 row">
                                                        <p className={dataList[key]['status'] === 'open' ? "bg-warning status-roll mr-1" :
                                                            dataList[key]['status'] === 'in progress' ? "in-progress status-roll mr-1" :
                                                                dataList[key]['status'] === 'fixed' ? "fixed status-roll mr-1" :
                                                                    dataList[key]['status'] === 'closed' ? "closed status-roll mr-1" :
                                                                        dataList[key]['status'] === 'to approve' ? "bg-primary status-roll mr-1" :
                                                                            dataList[key]['status'] === 'approved' ? "approved status-roll mr-1" :
                                                                                dataList[key]['status'] === 'reject' ? "bg-danger status-roll mr-1" :
                                                                                    "bg-secondary status-roll mr-1"}></p>
                                                        <p className="First-text ml-0 font8">{t(dataList[key]['status'])}</p>
                                                    </div>
                                                }
                                                <div className="col-md-6 ml-0 pr-0 row flex justify-content-end">
                                                    <p className="button-shape mb-0">
                                                        <img className="m-1 ButtonIcon"
                                                             onClick={() => getLocation(dataList[key])}
                                                             src={LocationIcon} title={t("Location")}
                                                             alt={"Button icon"}/>
                                                    </p>
                                                    {role !== 'fixer' && dataList[key]['priority'] === null &&
                                                        <p className="button-shape ml-3 mb-0">
                                                            <img className="m-1 task-hover ButtonIcon"
                                                                 onClick={() => ConfirmPopup(dataList[key].id)}
                                                                 src={AddIcon} title={t("Assign task")}
                                                                 alt={"Button icon"}/>
                                                        </p>}
                                                    {dataList[key]['priority'] !== null &&
                                                        <p className="button-shape ml-3 mb-0">
                                                            <img className="m-1 task-hover ButtonIcon"
                                                                 onClick={() => ConfirmStatusPopup(dataList[key])}
                                                                 src={StatusIcon} title={t("Change status")}
                                                                 alt={"Button icon"}/>
                                                        </p>}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </InfiniteScroll>
                    }
                    {dataList === undefined || dataList.length === 0 &&
                        <div className="col-md-12 col-12 row m-0 pl-0">
                            <h3 className="col-12 text-center title-color mt-5"> {t(tabStatus)} </h3>
                        </div>}

                </div>
            </div>
        </section>
    );
}
