import React, { useState, useEffect } from "react";
import "../Login/Login.css";
import "../Trashies/Trashie.css";
import "../HomeLayout/dashboard-style.css"
import Alert from 'react-bootstrap/Alert'
import { UpdateStatusUrl, GetStatusUrl } from "../Routes/ApiEndPoints";
import { APICALL as AXIOS } from "../Services/AxiosServices";
import { Modal, Button } from "react-bootstrap";
import {t} from '../Translation/Translation';

export default function UpdateStatus(complaint) {
    const [show, setShow] = useState(false);
    const [profileId, setProfileId] = useState('');
    const [status, setStatus] = useState(complaint.status);
    const [message, setMessage] = useState("");
    const [dataList, setDataList] = useState();


    useEffect(() => {
        setShow(true)
        setProfileId(localStorage.getItem('id'))
        let customer_id = localStorage.getItem('customer_id')
        //Api call to get all fixers
        let data = {
            "customer_id": customer_id,
            "complaint_id": complaint.complaint,
        }
        AXIOS.service(GetStatusUrl, "POST", data)
            .then(result => {
                setDataList(result)
            })
            .catch(error => {
                setDataList(undefined)
            })
    }, []);

    //Function to call update status api
    const updateStatus = () => {
        let data = {
            "complaint_id": complaint.complaint,
            "profile_id": profileId,
            "status": status,
        }

        AXIOS.service(UpdateStatusUrl, "POST", data)
            .then(result => {
                if (result['status'] === 200) {
                    complaint.onHide()
                    complaint.pageUpdate()
                } else {
                    setMessage(t(result['message']));
                }
            })
            .catch(error => {
                setMessage(t("Failed to change status."));
            })
    }

    return (
        <Modal
            show={show}
            onHide={complaint.onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="title-color ml-5 pl-5 dashboard-head border-bottom-0" closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="ml-5 pl-5 margin-left-space font-head font4">
                    {t('Change status')}
                </Modal.Title>
            </Modal.Header>
            <div className="col-md-12">
                {message.length > 0 && (
                    <Alert variant="danger" onClose={() => setMessage("")} dismissible>
                        <p className="font8">{t(message)}</p>
                    </Alert>
                )}
            </div>
            <Modal.Body className="col-md-12">
                <form>
                    {dataList === undefined && <div className="col-12 text-center mt-3" ><h6 className="text-center title-color"> {t("Loading Statuses")}.</h6> </div>}
                    {dataList !== undefined && Object.keys(dataList).map((key) => {
                        return (
                            <div className="">
                                <div className="col-md-12 form-check pb-1 m-0 row border-bottom ">
                                    <label className="col-md-10 padd-none col-10 row m-0 float-left">
                                        <p className={
                                            dataList[key]['id'] === 'open' ? "bg-warning status-color" :
                                            dataList[key]['id'] === 'in progress' ? "in-progress status-color" :
                                            dataList[key]['id'] === 'fixed' ? "fixed status-color" :
                                            dataList[key]['id'] === 'closed' ? "closed status-color" :
                                            dataList[key]['id'] === 'to approve' ? "bg-primary status-color" :
                                            dataList[key]['id'] === 'approved' ? "approved status-color" :
                                            dataList[key]['id'] === 'reject' ? "bg-danger status-color" : "bg-secondary status-color"}>
                                        </p>
                                        <p className="First-text font-para d-inline-flex">{t(dataList[key]['status'])}</p>
                                    </label>
                                    <input type="radio" className=" col-md-2 ml-4 mt-0 status-color form-check-input" id="radio1" name="optradio" value={dataList[key]['id']} onChange={(e) => setStatus(e.target.value)} checked={dataList[key]['id'] === status ? "checked" : ""} />
                                    <br />
                                </div>
                                <br />
                            </div>
                        );
                    })}
                </form>
            </Modal.Body>
            <Modal.Footer className="border-top-0 mr-4 button-danger">
                <Button className="head-sect react-button font-para font8" onClick={complaint.onHide}>{t('Close')}</Button>
                <Button className="head-sect react-button font-para font8" onClick={() => updateStatus()}>{t('Save')}</Button>
            </Modal.Footer>
        </Modal>
    );
}
