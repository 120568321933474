import React, { useState, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import { t } from '../Translation/Translation';
import {UpdateAiAssistanceActive} from "../Routes/ApiEndPoints";
import { APICALL as AXIOS } from "../Services/AxiosServices";
import ExampleImage from "../commonstyle/images/Aidataexample.png";

export default function AiAssistancePopup(props) {
    const { openBuyTokenModal } = props;
    const [currentScreen, setCurrentScreen] = useState(0);
    const [aiAssistancePopup, setAiAssistancePopup] = useState(false);
    const totalScreens = 3;

    let customer_id = localStorage.getItem('customer_id');
    let isAssistanceActive = localStorage.getItem("is_ai_assistance_active") === "true";

    const handleSubscribe = async () => {
        try {
            await AXIOS.service(UpdateAiAssistanceActive, "PUT", {
                customer_id: customer_id,
                is_ai_assistance_active: !isAssistanceActive,
            });
            localStorage.setItem("is_ai_assistance_active", !isAssistanceActive);
            props.setAiAssistanceActive(!isAssistanceActive);
            setAiAssistancePopup(false);
            props.onHide();
        } catch (error) {
            console.error('Error updating AI assistance:', error);
        }
    };

    function closeExampleOpenBuyModal() {
        setAiAssistancePopup(false);
        openBuyTokenModal(true);
    }

    return (
        <>
            <Modal
                show={isAssistanceActive === false}
                onHide={props.onHide}
                size='lg'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="title-color font-head font3">
                        AI Assistance
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentScreen === 0 && (
                        <div>
                            <h2 className="fa-bold">{t('What is AI Assistance?')}</h2>
                            <p>
                                {t('AI Assistance refers to a suite of powerful AI tools that utilize machine learning to analyze and interpret various types of data. In our application, it specifically helps in detecting trash in images, enhancing our ability to manage waste efficiently.')}
                            </p>

                            <h2 className="fa-bold">{t('How it works')}</h2>
                            <p>
                                {t('When an image is uploaded, our AI models will process it to identify trash and other relevant objects. This helps in automating the detection process, making it faster and more accurate.')}
                            </p>

                            <h4 className="fa-bold">{t('Upload your image:')}</h4>
                            <p>
                                {t('Select and upload the image you want to analyze.')}
                            </p>

                            <h4 className="fa-bold">{t('Analysis:')}</h4>
                            <p>
                                {t('Our AI models examine the image using advanced machine learning algorithms to detect trash.')}
                            </p>

                            <h4 className="fa-bold">{t('Result:')}</h4>
                            <p>
                                {t('Receive detailed insights about the image, such as identified trash and other notable elements.')}
                            </p>

                            <h1 className="fa-bold">
                                {t('Benefits for the user')}
                            </h1>
                            <p>
                                {t('This integration enhances our apps capabilities, allowing for more efficient waste management and improved environmental monitoring.')}
                            </p>
                            <Button onClick={() => setCurrentScreen(totalScreens - 1)} className="googleButtons font-para font8">{t('Skip explanation')}</Button>
                        </div>
                    )}
                    {currentScreen === 1 && (
                        <div>
                            <h1 className="fa-bold">{t('Example')}</h1>
                            <p>{t('This section will showcase examples demonstrating how AI models detect trash in images.')}</p>

                            <img src={ExampleImage} alt="Example"/>
                        </div>
                    )}
                    {currentScreen === 2 && (
                        <div>
                            <h2 className="fa-bold">{t('Getting Started with AI Assistance')}</h2>
                            <p>
                                {t('To start using AI Assistance, you will need to purchase tokens. Each token allows you to process one image. You can buy tokens from the dashboard.')}
                            </p>

                            <h4 className="fa-bold">{t('Steps to Purchase Tokens:')}</h4>
                            <ul>
                                <li>{t('Go to the dashboard and click on the "Buy tokens" button.')}</li>
                                <li>{t('Select the token package you wish to purchase.')}</li>
                                <li>{t('Complete the payment process to receive your tokens.')}</li>
                            </ul>

                            <h4 className="fa-bold">{t('Example for Clarity')}</h4>
                            <p>
                                {t('For example, if you have 100 tokens, you can analyze 100 different images. Additional tokens can be purchased at any time if needed.')}
                            </p>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button onClick={() => setCurrentScreen(currentScreen - 1)} disabled={currentScreen === 0}
                            className="googleButtons font-para font8">{t('Previous')}</Button>

                    <div style={{ marginLeft: 'auto' }}>
                        {currentScreen === 2 ? <Button className="googleButtons font-para font8" onClick={() => closeExampleOpenBuyModal()}>{t('Buy tokens')}</Button> : ""}

                        {currentScreen === (totalScreens - 1) ? <Button onClick={() => setAiAssistancePopup(true)} className="googleButtons font-para font8">{t('Enable')}</Button> :
                            <Button onClick={() => setCurrentScreen(currentScreen + 1)}
                                    disabled={currentScreen === totalScreens - 1}
                                    className="googleButtons font-para font8">{t('Next')}</Button>}
                    </div>
                </Modal.Footer>
            </Modal>

            {/* Confirm modal if AI Assistance is off */}
            <Modal
                show={aiAssistancePopup}
                onHide={() => setAiAssistancePopup(false)}
                size="md"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="title-color font-head font3">{t('Subscription')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('Are you sure you want to turn on AI assistance?')}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="head-sect react-button font-para font8"
                            onClick={() => setAiAssistancePopup(false)}>
                        {t('Cancel')}
                    </Button>
                    <Button className="head-sect mr-3 react-button font8 font-para" onClick={handleSubscribe}>
                        {t('Enable')}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Confirm modal if AI assistance is on */}
            <Modal
                show={isAssistanceActive === true}
                onHide={props.onHide}
                size="md"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="title-color font-head font3">{t('AI assistance')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('Are you sure you want to turn off AI assistance?')}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="head-sect react-button font-para font8"
                            onClick={props.onHide}>
                        {t('Cancel')}
                    </Button>
                    <Button className="head-sect mr-3 react-button font8 font-para" onClick={handleSubscribe}>
                        {t('Disable')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
