import { Table, Pagination } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { APICALL as AXIOS } from "../Services/AxiosServices";
import { FetchTokenPackageHistory } from "../Routes/ApiEndPoints";
import { t } from "../Translation/Translation";
import { useHistory } from "react-router-dom";
import BackButton from "../commonstyle/images/back_button.png";
import pdfIcon from "../commonstyle/images/pdfIcon.png";

export default function TokenPackagePurchaseHistoryPage() {
    const [tokenPackageHistory, setTokenPackageHistory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const history = useHistory();

    useEffect(() => {
        const fetchTokenPackagePurchaseHistory = async () => {
            const customer_id = localStorage.getItem('customer_id');
            const response = await AXIOS.service(FetchTokenPackageHistory, 'POST', {
                customer_id: customer_id,
            });
            if (response && Array.isArray(response.package_history)) {
                setTokenPackageHistory(response.package_history);
            } else {
                console.error("Response does not contain packages:", response);
            }
        };
        fetchTokenPackagePurchaseHistory();
    }, []);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        return tokenPackageHistory.slice(startIndex, startIndex + itemsPerPage);
    };

    const getPageNumbers = () => {
        const totalPages = Math.ceil(tokenPackageHistory.length / itemsPerPage);
        let pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(i);
        }
        return pages;
    };

    const formatDate = (dateString) => {
        try {
            // Parse the input string as a Date object
            const date = new Date(dateString);

            // Check if the date is valid
            if (isNaN(date.getTime())) {
                throw new Error(`Invalid date format: ${dateString}`);
            }

            // Format the date part
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
            const day = String(date.getDate()).padStart(2, '0');

            // Format the time part
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            // Construct the date and time string with space in between
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        } catch (error) {
            console.error("Error parsing date:", error);
            return 'Invalid date';
        }
    };

    const handleInvoiceClick = () => {
        console.log("Invoice clicked")
    };

    return (
        <>
            <div className="container-fluid main-box">
                <div className="row title-color pl-0 mt-0 font-weight-bold">
                    <div className="col-md-12 col-12">
                        <img
                            className="backbutton float-left cursor"
                            src={BackButton}
                            onClick={history.goBack}
                            title={t('Click to go back')}
                            alt={"back button"}
                        ></img>
                        <h3 className="detailpage-title ml-5 font2">{t('Dashboard')}</h3>
                    </div>
                </div>
                <div className="container mx-auto mt-8 p-6 bg-white rounded-lg shadow-md pb-2 pt-3">
                    <h2 className="text-2xl font-bold mb-4">{t('Token Package Purchase History')}</h2>
                    <Table striped bordered hover className="mb-4">
                        <thead>
                        <tr>
                            <th>{t('Package name')}</th>
                            <th>{t('Purchased on')}</th>
                            <th>{t('Unit(s) purchased')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Array.isArray(tokenPackageHistory) && tokenPackageHistory.length > 0 ? (
                            getPaginatedData().map((history, index) => (
                                <tr key={index}>
                                    <td>{history.token_package_name}</td>
                                    <td>{formatDate(history.purchased_on)}</td>
                                    <td>{history.token_package_amount}</td>
                                    <td onClick={() => handleInvoiceClick()}>
                                        <img
                                            src={pdfIcon}
                                            alt="PDF Icon"
                                            title={t('View purchase details')}
                                            style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                                        />
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3">{t('No package history found')}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                    {tokenPackageHistory.length > itemsPerPage && (
                        <Pagination className="flex justify-center mb-4">
                            {getPageNumbers().map((number) => (
                                <Pagination.Item
                                    className="bg-gray-300 mx-1"
                                    key={number}
                                    active={number === currentPage}
                                    onClick={() => handlePageChange(number)}
                                    linkStyle={{ "color": "white", "background-color": "orange" }}
                                >
                                    {number}
                                </Pagination.Item>
                            ))}
                        </Pagination>
                    )}
                </div>
            </div>
        </>
    );
}
