import React, { useState } from 'react';
import { APICALL as AXIOS } from "../Services/AxiosServices";
import { BuyTokenPackage } from "../Routes/ApiEndPoints";
import buyTokenImage from "../commonstyle/images/buy-cart.png";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Form, Radio } from "antd";
import { t } from "../Translation/Translation";

export default function BuyTokenPopup(props) {
    const { show, onHide, setBuyTokenPopupConfirm, buyTokenPopupConfirm, tokenPackages } = props;
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState('');

    const handleBuyTokens = async (selectedPackage) => {
        const customer_id = localStorage.getItem('customer_id');
        try {
            const response = await AXIOS.service(BuyTokenPackage, 'POST', {
                customer_id: customer_id,
                package_id: selectedPackage.id,
            });
            setPaymentUrl(response.payment_link);
            setBuyTokenPopupConfirm(!buyTokenPopupConfirm);
        } catch (error) {
            console.error(t('Error while buying tokens:'), error);
        } finally {
            setLoading(false);
        }
    };

    const handleConfirmPurchase = () => {
        if (selectedPackage) {
            setLoading(true);
            handleBuyTokens(selectedPackage);
        }
    };

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="title-color font-head font3">
                        {t('Buy Tokens')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!paymentUrl ? (
                        <>
                            <h3 style={{ marginBottom: '1.5rem' }} className="fa-bold">{t('Token Packages')}</h3>
                            <Form>
                                <Radio.Group
                                    style={{ display: 'flex', flexDirection: 'column' }}
                                    onChange={(e) => setSelectedPackage(tokenPackages.find(pkg => pkg.id === e.target.value))}
                                >
                                    {tokenPackages && tokenPackages.map((pkg) => (
                                        <div
                                            key={pkg.id}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginBottom: '1rem',
                                                backgroundColor: selectedPackage?.id === pkg.id ? 'rgba(173, 216, 230, 0.3)' : 'transparent',
                                                padding: '1rem',
                                                borderRadius: '5px'
                                            }}
                                        >
                                            <img src={buyTokenImage} alt={`${pkg.package_name.charAt(0).toUpperCase() + pkg.package_name.slice(1)} ${t('Package')}`}
                                                 style={{ width: '50px', height: '50px', marginRight: '1rem' }} />
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Radio checked={pkg.id === 0} value={pkg.id} style={{ marginRight: '1.0rem', marginTop: '0.5rem' }} />
                                                <div>
                                                    <strong>{`${pkg.package_name.charAt(0).toUpperCase() + pkg.package_name.slice(1)} Package`}</strong>
                                                    <div>{`${pkg.token_count.toLocaleString()} ${t('tokens for $')}${pkg.price}`}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Radio.Group>
                            </Form>
                        </>
                    ) : (
                        <iframe
                            src={paymentUrl}
                            title="Payment"
                            style={{ width: '100%', height: '500px', border: 'none' }}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {!paymentUrl && (
                        <Button
                            onClick={handleConfirmPurchase}
                            disabled={loading || !selectedPackage}
                            className="googleButtons font-para font8"
                        >
                            {loading ? <Spinner as="span" animation="border" size="md" role="status" aria-hidden="true" /> : t('Buy tokens')}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
}
