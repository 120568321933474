import React, {useState, useEffect} from "react";
import "./Login.css";
import Alert from "react-bootstrap/Alert";
import {useAppContext} from "../Routes/contextLib";
import {Link} from "react-router-dom";
import {LoginUrl, GetStatusUrl, GetAiAssistanceActive} from "../Routes/ApiEndPoints";
import {APICALL as AXIOS} from "../Services/AxiosServices";
import {t} from '../Translation/Translation';
import {useHistory} from "react-router-dom";

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const {isAuthenticated, userHasAuthenticated} = useAppContext();
    const history = useHistory();

    useEffect(() => {
        if (isAuthenticated) {
            history.push("/");
        }
    });

    const saveStatus = (customer_id) => {
        //Api call to get all fixers
        let data = {
            customer_id: customer_id,
            complaint_id: "None",
        };
        AXIOS.service(GetStatusUrl, "POST", data).then((result) => {
            Object.keys(result).map((key) => {
                localStorage.setItem(result[key]["id"], result[key]["status"]);
            });
        });
    };


    //Function to call login api
    function handleSubmit(event) {
        event.preventDefault();

        let data = {
            username: email,
            password: password,
        };

        let customer_id = 0
        AXIOS.service(LoginUrl, "POST", data)
            .then(async (result) => {
                if (result["status"] === 200) {
                    localStorage.setItem("id", result["profile_id"]);
                    localStorage.setItem("customer_id", result["customer_id"]);
                    customer_id = result["customer_id"];
                    localStorage.setItem("role", result["role"]);
                    localStorage.setItem("token", "token" + " " + result["token"]);
                    userHasAuthenticated(true);
                    localStorage.setItem("isAuthenticated", true);
                    localStorage.setItem("is_ai_assistance_active", await getAiAssistanceActive());
                    saveStatus(result["customer_id"]);
                    history.push("/");
                } else {
                    userHasAuthenticated(false);
                    localStorage.setItem("isAuthenticated", false);
                    setMessage(t("Invalid Email or Password"));
                    console.log("Error message:", message); // Log the message
                }
            })
            .catch(() => {
                userHasAuthenticated(false);
                localStorage.setItem("isAuthenticated", false);
                setMessage(t("Error logging in with these credentials"));
                console.log("Error message:", message); // Log the message
            });


        async function getAiAssistanceActive() {
            try {
                const response = await AXIOS.service(GetAiAssistanceActive, "POST", {
                    customer_id: customer_id,
                });
                //console.log(response)
                return response.is_ai_assistance_active;

            } catch (error) {
                console.error('Error retrieving AiAssistanceActive:', error);
                throw error;
            }
        }
    }

    return (
        <section>
            <div className="container">
                <div className="col-md-12">
                    {/* <div className="col-md-6 float-left"><div className="col-md-12 justify-content-center"><img className="trash-can-main" src={TrashCanImage} /></div></div> */}
                    <div className="col-md-6 col-lg-6 col-sm-11 col-12 login p-3 border shadow bg-white">
                        <div className="col-md-12 col-12 padd-none">
                            <h2 className="col-md-12 col-12 padd-none dash-desc login-title title font2 mb-4 mt-2">
                                Login
                            </h2>
                            {message &&
                                <div className="col-md-12 no-padd padd-none">
                                    <Alert
                                        variant="danger"
                                        onClose={() => setMessage("")}
                                        dismissible
                                    >
                                        <p>{message}</p>
                                    </Alert>
                                </div>
                            }
                            <form onSubmit={handleSubmit}>
                                <div className="col-md-12 mb-4 no-padd padd-none bottom-space form-inline">
                                    <label className="col-md-12 col-lg-3 pl-0 dash-desc justify-content-start">
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        name="email"
                                        className="col-md-12 col-lg-9 col-sm-12 form-control bg-light"
                                        value={email}
                                        id="email"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-md-12 mb-4 no-padd padd-none bottom-space form-inline">
                                    <label className="col-md-12 col-lg-3 pl-0 dash-desc justify-content-start">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        className="col-md-12 col-lg-9 col-sm-12 form-control bg-light"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-md-12 no-padd padd-none bottom-space no-space form-group mt-1">
                                    <p className="text-right mt-0 mb-0">
                                        <Link to="/forgotpassword" className="text-dark font8">
                                            Forgot password
                                        </Link>
                                    </p>
                                </div>
                                <div className="col-md-12 no-padd padd-none mt-0 mb-0">
                                    <button
                                        type="submit"
                                        className="btn mybtn font-weight-bold text-white mb-2 font-para font8 float-right"
                                    >
                                        {" "}
                                        Login{" "}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
