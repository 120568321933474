import React, { useState, useEffect } from "react";
import "../Login/Login.css";
import Alert from 'react-bootstrap/Alert'
import { GetFixersUrl, AssignFixerUrl } from "../Routes/ApiEndPoints";
import { APICALL as AXIOS } from "../Services/AxiosServices";
import { Modal, Button } from "react-bootstrap";
import { t } from '../Translation/Translation';

export default function AssignFixer(complaint) {
    const [dataList, setDataList] = useState();
    const [show, setShow] = useState(false);
    const [fixer, setFixer] = useState(complaint.fixer_id ? complaint.fixer_id : '');
    const [priority, setPriority] = useState(complaint.priority ? complaint.priority : '');
    const [instruction, setInstruction] = useState(complaint.instruction ? complaint.instruction : "No instruction");
    const [message, setMessage] = useState("");
    const [customerAdmin, setcustomerAdmin] = useState('None');

    useEffect(() => {
        setShow(true)
        setcustomerAdmin(localStorage.getItem('id'))
        let customer_id = localStorage.getItem('customer_id')
        //Api call to get all fixers
        let data = {
            "customer_id": customer_id,
            "status": '0'
        }
        AXIOS.service(GetFixersUrl, "POST", data)
            .then(result => {
                setDataList(result)
            })
            .catch(() => {
                setDataList(undefined)
            })
    }, []);

    //Function to call api to assign selected fixer
    const assignFixer = () => {
        if (priority === '') {
            setMessage(t("Select priority"))
            setShow(true)
        } else {
            let data = {
                "complaint_id": complaint.complaint,
                "fixer_id": fixer,
                "customer_admin_id": customerAdmin,
                "priority": priority,
                "instruction": instruction,
            }

            AXIOS.service(AssignFixerUrl, "POST", data)
                .then(result => {
                    if (result['status'] === 200) {
                        complaint.onHide()
                        complaint.pageUpdate()
                    } else {
                        setMessage(t(result['message']));
                    }
                })
                .catch(() => {
                    setMessage(t("Failed to assign. Enter correct data"));
                })
        }
    }

    return (
        <Modal
            show={show}
            onHide={complaint.onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="title-color  dashboard-head border-bottom-0">
                <Modal.Title id="contained-modal-title-vcenter" className="mx-auto px-auto margin-left-space font-head font4" >
                    {t('Assign to fixer')}
                </Modal.Title>
            </Modal.Header>
            <div className="closePop close" onClick={complaint.onHide}><span aria-hidden="true">×</span><span className="sr-only">{t('Close')}</span></div>

            <div className="col-md-12">
                {message.length > 0 && (
                    <Alert variant="danger" onClose={() => setMessage("")} dismissible>
                        <p className="font9">{t(message)}</p>
                    </Alert>
                )}
            </div>
            <Modal.Body className="mx-4">
                <select id="fixer" className="col-md-12 p-1 d-flex border input-border rounded-lg" name="fixer" onChange={(e) => setFixer(e.target.value)} required title={fixer ? null : t("Please select an item in the list")}>
                    <option value={complaint.fixer_id ? complaint.fixer_id : ""} className="font-para" >{complaint.fixer ? complaint.fixer : t('select fixer')}</option>
                    {dataList !== undefined && Object.keys(dataList).map((key) => {
                        if (complaint.fixer_id !== dataList[key]['id']) {
                            return (
                                <option value={dataList[key]['id']} className="font-para">{dataList[key]['fixer']}</option>
                            );
                        }
                    })}
                </select>
                <br />
                <select id="priority" className="col-md-12 p-1 d-flex border input-border rounded-lg" name="priority" onChange={(e) => setPriority(e.target.value)} required title={priority ? null : t('Please select an item in the list')}>
                    <option value={complaint.priority ? complaint.priority : ""} className="font-para" >{complaint.priority ? complaint.priority : t('select priority')} </option>
                    {complaint.priority !== 'critical' && <option value="critical" className="font-para">critical</option>}
                    {complaint.priority !== 'high' && <option value="high" className="font-para">high</option>}
                    {complaint.priority !== 'low' && <option value="low" className="font-para">low</option>}
                    {complaint.priority !== 'medium' && <option value="medium" className="font-para">medium</option>}
                </select>
                <br />
                <div className="col-md-12 p-0">
                    <label className="col-md-12 ml-0 pl-0 font-head">{t('Add more instructions')}</label>
                    <textarea className="col-md-12 pl-1 pr-1 border input-border rounded-lg font-para" rows="7" maxLength="400" id="comment" name="text" onChange={(e) => setInstruction(e.target.value)}>{complaint.instruction ? complaint.instruction : ""}</textarea>
                </div>
            </Modal.Body>
            <Modal.Footer className="border-top-0 mr-4 button-danger">
                <Button className="head-sect react-button font-para font8" onClick={complaint.onHide}>{t('Close')}</Button>
                <Button className="head-sect react-button font-para font8" onClick={() => assignFixer()}>{t('Assign')}</Button>
            </Modal.Footer>
        </Modal>
    );
}
