import React from "react";
import {t} from '../Translation/Translation';
import {parseGeminiData, parseGoogleVisionData} from "../Services/AiDataParsers";

const TrashDetailsToPrint = React.forwardRef((props, ref) => {
    const data = props.data
    const user = props.user
    return (
        <div className="w-100" ref={ref}>
            <div className="pdf-print">
                <h1 className="ml-4 mt-2">{t('Trash details')}</h1>
                <img src={data.image} className="print-trash-image ml-4 m-3" alt={"Data image"}></img>
                <h1 className="mt-4 mb-4 ml-4">{t('Reported trash details')}:</h1>
                <div className="row mb-2">
                    <div className="lable-name ml-5"><h3>{t('Title')} : </h3></div>
                    <div className="data-name First-text"><h3>{data.title}</h3></div>
                </div>
                <div className="row mb-2">
                    <div className="lable-name ml-5"><h3>{t('Status')} : </h3></div>
                    <div className="data-name First-text"><h3>{data.status}</h3></div>
                </div>
                <div className="row mb-2">
                    <div className="lable-name ml-5"><h3>{t('Created date')} : </h3></div>
                    <div className="data-name First-text">
                        <h3>{data.date.split('T').slice(0, -1).join("T").split("-").reverse().join("-")}</h3></div>
                </div>
                <div className="row mb-2">
                    <div className="lable-name ml-5"><h3>{t('Description')} : </h3></div>
                    <div className="data-name First-text ">
                        <h3>{data.description === 'No description' ? t('No description') : data.description}</h3></div>
                </div>
                <div className="row mb-2">
                    <div className="lable-name ml-5"><h3>{t('Address')} : </h3></div>
                    <div className="data-name First-text"><h3>{data.location}</h3></div>
                </div>
                <div className="row mb-2">
                    <div className="lable-name ml-5"><h3>{t('Priority')} : </h3></div>
                    <div className="data-name First-text"><h3>{data.priority}</h3></div>
                </div>
                <div className="row mb-2">
                    <div className="lable-name ml-5"><h3>{t('Instruction')} : </h3></div>
                    <div className="data-name First-text"><h3>{data.instruction}</h3></div>
                </div>
                <h1 className="mt-5 pt-5 mb-4 ml-4">{t('Reported user details')}:</h1>
                <div className="row mb-2">
                    <div className="lable-name ml-5"><h3>{t('Name')} : </h3></div>
                    <div className="data-name First-text"><h3>{user.name}</h3></div>
                </div>
                <div className="row mb-2">
                    <div className="lable-name ml-5"><h3>{t('Email')} : </h3></div>
                    <div className="data-name First-text"><h3>{user.email}</h3></div>
                </div>
                <div className="row mb-2">
                    <div className="lable-name ml-5"><h3>{t('Contact')} : </h3></div>
                    <div className="data-name First-text"><h3>{user.phone_number}</h3></div>
                </div>
                <div className="row mb-2">
                    <div className="lable-name ml-5"><h3>{t('Gender')} : </h3></div>
                    <div className="data-name First-text"><h3>{user.gender}</h3></div>
                </div>
                <div className="row mb-2">
                    <div className="lable-name ml-5"><h3>{t('Location')} : </h3></div>
                    <div className="data-name First-text"><h3>{user.location}</h3></div>
                </div>
                {props.ai_data !== null && (
                    <div className="row mb-2">
                        <div className="lable-name ml-5">
                            <h3>{t('Ai analysis Details')} : </h3>
                        </div>
                        <div className="bar-chart-container">

                            {props.ai_data.ai_model_name === "gemini-pro-vision" ?
                                parseGeminiData(props.ai_data):

                            props.ai_data.ai_model_name === "google-vision" ?
                                parseGoogleVisionData(props.ai_data):

                                null

                            //     Add more for any new AI Models.
                            }
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
})

export default TrashDetailsToPrint;
