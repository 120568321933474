import {APICALL as AXIOS} from "../Services/AxiosServices";
import {fetchTranslations} from '../Routes/ApiEndPoints';
import store from '../Redux/store';

// statics handler
const lookupTranslations = (key, locale, lookup) =>{
  let translations = JSON.parse(localStorage.getItem('translations')) || false;
  if(lookup){
    return (translations) ? ((key in translations[locale]) ? true : false) : false;
  }else{
    return (translations) ? translations[locale][key] || false : false;
  }
}

const updateTranslations = (key, translation) =>{
  let translations = JSON.parse(localStorage.getItem('translations')) || false;
  if(translations){
    let target_key = get_target_key(key);

    Object.keys(translation).map(lang =>{
      translations[lang][target_key] = translation[lang][target_key];
    });
    localStorage.setItem('translations', JSON.stringify(translations))
  }
}

const get_target_key = (key) => {
  return (key.replace(/ /g,'_'));
}

export const t = (key) => {
  const reduxState = store.getState();
  let locale = localStorage.getItem('language') || 'en' ;

  let target_key = get_target_key(key);
  let translation = lookupTranslations(target_key, locale, true);

  if (!translation){
    let postData = {
      key : key,
      locale : locale,
    }
    AXIOS.service(fetchTranslations,'POST', postData) // if string not exists, then store that in table
      .then(result => {
        updateTranslations(target_key, result);
        translation = lookupTranslations(target_key, locale);
      })
  }else{
    translation = lookupTranslations(target_key, locale);
  }
  return (translation) ? translation : key;
}

export default {
  t,
}
