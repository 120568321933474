import React from 'react'
import { Modal } from 'react-bootstrap';
// import CloseIcon from '../commonstyle/images/Close.png';
import '../Trashies/Trashie.css';
// import ClearIcon from "@material-ui/icons/Clear";


function FullScreenImage({imageSrc, DisableFullScreenImage}) {
    const imageData = imageSrc;
    function CloseImage() {
        DisableFullScreenImage();
    }
    return (
        <Modal show={true} onHide={CloseImage} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header className="p-0"> 
        <div className="w-100">
            <span className="close-btn cursor" onClick={CloseImage}>x</span>
            <img src={imageData} alt="fullScreenImage" className="image-full-screen-style"></img>
        </div>
        </Modal.Header> 
        </Modal>
    )
}

export default FullScreenImage