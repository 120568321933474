import React from 'react';
import { Modal, Button } from "react-bootstrap";
import {t} from '../Translation/Translation';

export default function ModalPopup(info) {
    const show = true

    return (
        <Modal
            show={show}
            onHide={info.onHide}
            size='md'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="title-color font-head font3">
                    {t('Detailed information')}
          </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-md-12">
                    <div className="col-md-12 col-12 row padd-none margin-none"><h6 className="row col-md-4 col-4">{t('Email')}:</h6> <p className="col-md-8 col-8">{info.info.email}</p></div>
                    <div className="col-md-12 col-12 row padd-none margin-none"><h6 className="row col-md-4 col-4">{t('Contact')}:</h6> <p className="col-md-8 col-8">{info.info.phone_number}</p></div>
                    <div className="col-md-12 col-12 row padd-none margin-none"><h6 className="row col-md-4 col-4">{t('Gender')}:</h6> <p className="col-md-8 col-8">{info.info.gender}</p></div>
                    <div className="col-md-12 col-12 row padd-none margin-none"><h6 className="row col-md-4 col-4">{t('Location')}:</h6> <p className="col-md-8 col-8">{info.info.location}</p></div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="head-sect react-button font-para font8" onClick={info.onHide}>
                    {t('Close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}


