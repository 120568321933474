import React, {useState, useEffect} from "react";
import "./dashboard-style.css";
import {useAppContext} from "../Routes/contextLib";
import {GetAiAssistanceActive, GetComplaintsUrl, GetTokenPackages, GraphCountUrl} from "../Routes/ApiEndPoints";
import {APICALL as AXIOS} from "../Services/AxiosServices";
import GetMap from "./GetLocations";
import Graph from "./Graph";
import CreatefixerPng from "../commonstyle/images/Createfixer.png"
import ManagefixerPng from "../commonstyle/images/Managefixer.png"
import GooglevisionApi from "../commonstyle/images/GooglevisionApi.png"
import GooglevisionApiOff from "../commonstyle/images/GooglevisionApiOff.png"
import TokenHistory from "../commonstyle/images/TokenHistory.png"
import TokenImage from "../commonstyle/images/token-trashap.png"
import AddIcon from "../commonstyle/images/Add.png"
import AssignFixer from "../Fixer/AssignFixer";
import UpdateStatus from "../Fixer/UpdateStatus";
import TrashIcon from "../commonstyle/images/Trashapcan.png"
import InfoIcon from "../commonstyle/images/Moreinfo.png"
import StatusIcon from "../commonstyle/images/Changestatus.png"
import {t} from '../Translation/Translation';


import {useHistory} from "react-router-dom";
import AiAssistancePopup from "../Popup/AiAssistancePopup";
import ListComponent from "./ListComponent";
import BuyTokenPopup from "../Popup/BuyTokenPopup";
import {Button, Modal} from "react-bootstrap";
import {TokenHistoryPage} from "../TokenHistory/TokenHistoryPage";

export default function DashBoard() {
    const [statusupdate, setstatusupdate] = useState(false);
    const [dataList, setDataList] = useState();
    const [monthsList, setMonthsList] = useState();
    const [graphList1, setFirstGraphList] = useState();
    const [graphList2, setSecondGraphList] = useState();
    const [graphList3, setThirdGraphList] = useState();
    const [countList, setCountList] = useState(graphList1);
    const role = localStorage.getItem('role')
    const [complaintId, setComplaint] = useState();
    const [Status, setStatus] = useState();
    const [showpopup, setShowpopup] = useState(false);
    const [showstatuspopup, setshowstatuspopup] = useState(false);
    const [aiAssistancePopup, setAiAssistancePopup] = useState(false);
    const [buyTokenModal, setBuyTokenModal] = useState(false);
    const [buyTokenPopupConfirm, setBuyTokenPopupConfirm] = useState(false);
    const [tokenPackages, setTokenPackages] = useState([]);
    const {isAuthenticated, userHasAuthenticated} = useAppContext();
    const history = useHistory();
    const [showHistory, setShowHistory] = useState(false);
    const regex = /(<([^>]+)>)/ig;

    const [isAiAssistanceActive, setAiAssistanceActive] = useState(localStorage.getItem("is_ai_assistance_active") === "true");
    const [lowTokenModal, setLowTokenModal] = useState(false);

    const [tokenBalance, setTokenBalance] = useState();
    const customer_id = localStorage.getItem("customer_id")
    //Consition to check authorization is done or not, if not done then push to login page
    useEffect(() => {
        if (isAuthenticated !== true) {
            history.push("/login");
        }
    });

    useEffect(() => {
        async function fetchTokenPackages() {
            try {
                const response = await AXIOS.service(GetTokenPackages, 'POST');
                setTokenPackages(response);
            } catch (error) {
                console.error('Error fetching token packages:', error);
            }
        }

        fetchTokenPackages();
    }, []);

    const handleShowBuyTokenModal = () => setBuyTokenModal(true);
    const handleCloseBuyTokenModal = () => setBuyTokenModal(false);
    const handleShowAiAssistanceModal = () => setAiAssistancePopup(true);
    const handleCloseAiAssistanceModal = () => setAiAssistancePopup(false);
    const handleLowTokenModalClose = () => setLowTokenModal(false);

    // Function to call get complaints api
    useEffect(() => {
        let profile_id = localStorage.getItem('id')
        //Api call to get all complaints
        let data = {
            "profile_id": profile_id,
            "app_type": "trashap",
            "offset": 0,
            "status": 'all',
            "monthId": 0,
            "searchVal": ''
        }
        AXIOS.service(GetComplaintsUrl, "POST", data)
            .then(result => {
                if (result.data.length !== 0) {
                    setDataList(result.data)
                } else {
                    setDataList([])
                }
            })
            .catch(error => {
                setDataList(undefined)
            })

    }, [statusupdate]);

    useEffect(() => {
        AXIOS.service(GetAiAssistanceActive, "POST", {
            customer_id: customer_id,
        })
            .then(result => {
                setTokenBalance(result.token_balance);
            })
            .catch(error => {
                console.error('Error getting the token balance:', error);
            });
    }, [buyTokenModal]);

    //Api call to get counts
    useEffect(() => {
        let customer_id = localStorage.getItem('customer_id')
        let user_id = localStorage.getItem('role') === 'fixer' ? localStorage.getItem('id') : 0
        let data = {
            "customer_id": customer_id,
            "app_type": 'trashap',
            "profile_id": user_id
        }

        AXIOS.service(GraphCountUrl, "POST", data)
            .then(result => {
                setCountList(result['0'])
                setFirstGraphList(result['0'])
                setSecondGraphList(result['1'])
                setThirdGraphList(result['2'])
                setMonthsList(result['months'])
            })
            .catch(error => {
                setFirstGraphList(undefined)
            })
    }, [statusupdate]);

    useEffect(() => {
        if (tokenBalance < 50) {
            setLowTokenModal(true);
        }
    }, [tokenBalance]);


    //Function to go to detail page with complaint id
    const GoToDetailPage = (val) => {
        history.push('trashdetail/' + val)
    }

    //Function for assign fixer popup
    const ConfirmPopup = (val) => {
        setComplaint(val)
        setShowpopup(true)
    }

    const ConfirmStatusPopup = (val) => {
        setComplaint(val['id'])
        setStatus(val['status'])
        setshowstatuspopup(true)
    }

    //Function to close popup
    const closePopup = () => {
        setShowpopup(false)
        setshowstatuspopup(false)
    }

    function pageUpdate() {
        setstatusupdate(!statusupdate)
    }

    return (
        <section className="content">
            <div className="container-fluid pt-3 dashboard-main-container">
                <div className="col-md-12 px-0 dashboard-head title-color">
                    <h4 className="col-md-4 px-0 font2">{t('Dashboard')}</h4>
                </div>
                <div className="col-md-12 px-0 col-12 row m-0 padd-none">
                    <div
                        className="col-md-6 col-sm-12 col-12 defined-width float-left mt-1 left-container border shadow bg-white">
                        <div className="col-md-12 col-12 mt-3 title-color border-bottom pl-0">
                            <h5 className="font6">{t('Latest reported trashies')}</h5>
                        </div>
                        {showpopup === true &&
                            <AssignFixer complaint={complaintId} onHide={closePopup} pageUpdate={pageUpdate}/>}
                        {showstatuspopup === true &&
                            <UpdateStatus complaint={complaintId} status={Status} onHide={closePopup}
                                          pageUpdate={pageUpdate}/>}
                        {dataList === undefined && <div className="col-12 text-center mt-3">
                            <h4 className="text-center title-color"> {t("Loading Trashies")}.</h4>
                        </div>}
                        {dataList !== undefined && Object.keys(dataList).map((key) => {
                            if (key < 4) {
                                return (
                                    <div className="col-md-12 col-12 m-0 px-0 py-2 dashboard-trashie row border-bottom"
                                         key={dataList[key].id}>
                                        <div className="col-md-3 col-3 image-box"><img src={dataList[key]['image']}
                                                                                       className={role !== "fixer" ? "dashboard-image rounded-lg" : "fixer-dashboard-image rounded-lg"}
                                                                                       alt="trash"></img></div>
                                        <div className="col-md-9 col-9 pl-2 pr-0 no-left-padding">
                                            <h6 className="col-md-12 col-12 mb-2 First-text font4">{dataList[key]['title']}</h6>
                                            <p className="col-md-12 col-12 pr-0 mb-3 dash-desc First-text font7">{dataList[key]['description'] === 'No description' ? t('No description') : (dataList[key]['description'].length > 55 ?
                                                dataList[key]['description'].replace(regex, '').substring(0, 50) + '...' : dataList[key]['description'])}</p>
                                            <div className="col-md-12 col-12 row pr-0 m-0">
                                                <p className="col-md-5 col-sm-5 col-12 pl-0 pr-0 mt-1 mb-0 row m-0"><p
                                                    className="mb-0">{t('Uploaded on')}:</p><p
                                                    className="mb-0"> {dataList[key]['date'].split('T').slice(0, -1).join("T").split("-").reverse().join("-")}</p>
                                                </p>
                                                <div
                                                    className="col-md-3 col-sm-3 col-5 status-button-width marginTop row ml-0 mr-0 pr-0 pl-0 mt-1 assign-status">{dataList[key]['priority'] !== null ?
                                                    <div className="row mb-0 padding-left-status">
                                                        <p className="bg-success status rounded-circle mr-1"></p>{t('Assigned')}
                                                    </div> :
                                                    <div className="row mb-0 pl-2 unassign-left">
                                                        <p className="bg-secondary status rounded-circle mr-1"></p>{t('Unassigned')}
                                                    </div>}
                                                </div>
                                                <div
                                                    className="col-md-4 col-sm-4 col-7 status-button-width marginTopButton pr-0 pl-4 row button-allign">
                                                    <p className="left-shape ml-3 margin-remove mb-0 cursor"><img
                                                        className="m-1 moreInfoIcon"
                                                        onClick={() => GoToDetailPage(dataList[key].id)} src={InfoIcon}
                                                        title={t("More info")} alt={"moreInfo icon"}/></p>
                                                    {role !== 'fixer' && dataList[key]['priority'] === null &&
                                                        <div><p className="left-shape ml-3 mb-0 cursor"><img
                                                            className="m-1 moreInfoIcon"
                                                            onClick={() => ConfirmPopup(dataList[key].id)} src={AddIcon}
                                                            title={t("Assign task")} alt={"More info icon"}/></p></div>}
                                                    {dataList[key]['priority'] !== null &&
                                                        <div><p className="left-shape ml-3 mb-0 cursor"><img
                                                            className="m-1 moreInfoIcon"
                                                            onClick={() => ConfirmStatusPopup(dataList[key])}
                                                            src={StatusIcon} title={t("Change status")}
                                                            alt={"More info icon"}/></p></div>}
                                                </div>
                                                {/* <div className="col-md-6 pl-2 pr-0 mt-1 row ml-0 mr-0 actions ">
                                                    <small className="p-1 row ml-0 mr-0 head-sect actions" onClick={() => GoToDetailPage(dataList[key].id)}>More info<img className="info-icon pl-1" src={InfoIcon} alt="icon" /></small>
                                                    {role !== 'fixer' && dataList[key]['priority'] === null && <small className="p-1 row ml-2 mr-0 head-sect actions" onClick={() => ConfirmPopup(dataList[key].id)}>Assign task<img className="info-icon pl-1" src={AddIcon} alt="icon" /></small>}
                                                    {dataList[key]['priority'] !== null && <small className="p-1 pr-2 row ml-2 mr-0 head-sect actions" onClick={() => ConfirmStatusPopup(dataList[key])}>Change status<img className="info-icon pl-1" src={StatusIcon} alt="icon" /> </small>}
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        })}
                        {dataList === undefined || dataList.length === 0 &&
                            <h3 className="col-12 text-center title-color mt-5"> {t("No trashies found")} </h3>}
                        {dataList !== undefined && dataList.length !== 0 && <div
                            className={role !== "fixer" ? "float-right btn more-part head-sect mr-2" : "float-right btn btn-sm fixer-more head-sect mr-2"}
                            onClick={() => history.push('/trashlist/all/0')}>{t('More')}</div>}
                    </div>
                    <div
                        className="col-md-6 col-sm-12 col-12 defined-width pr-0 float-right dashboard-head dashboard-right-head right-container">
                        <div className="col-md-12 col-12 px-0 map-container mt-1 border shadow bg-white">
                            <div className="col-md-12 mt-3 title-color border-bottom"><h5
                                className="font6">{t('All trashies on map')}</h5></div>
                            <div className="col-md-12 col-12 mt-3 pl-3 pr-3 mb-3"><GetMap type='dashboard'
                                                                                          statusupdate={statusupdate}></GetMap>
                            </div>
                        </div>
                        <div className="col-md-12 col-12 px-0 action-container mt-2 border shadow bg-white">
                            <div className="row">
                                <div className="col-md-8 mt-3 title-color border-bottom">
                                    <h5 className="font6 pl-2">
                                        {t('Actions')}
                                    </h5>
                                </div>
                                <div className="col-md-4 mt-3 title-color d-flex justify-content-center border-bottom"
                                     onClick={() => history.push('token-history')} style={{cursor: 'pointer'}}>
                                    <h5 className="font6 ml-1 hover-effect">
                                        {t('Image credits')}
                                    </h5>
                                </div>
                            </div>
                            <div className="row border-bottom">
                                <div className="col-md-8 p-0">
                                    {role !== 'fixer' && <nav className="navbar col-12 navbar-expand">
                                        <ul className="navbar-nav mt-1 cursor">
                                            <ListComponent onClick={() => history.push("createfixer/")}
                                                           imageSrc={CreatefixerPng} altText="Create fixer png"
                                                           buttonText={t('Create fixer')}/>
                                            <ListComponent onClick={() => history.push("managefixer/")}
                                                           imageSrc={ManagefixerPng} altText="Manage fixer png"
                                                           buttonText={t('Manage fixer')}/>

                                            {isAiAssistanceActive ?
                                                <ListComponent onClick={handleShowAiAssistanceModal}
                                                               imageSrc={GooglevisionApiOff}
                                                               altText="Ai Assistance off"
                                                               buttonText={t('Turn off AI assistance')}/>
                                                :
                                                <ListComponent onClick={handleShowAiAssistanceModal}
                                                               imageSrc={GooglevisionApi}
                                                               altText="Ai Assistance on"
                                                               buttonText={t('Turn on AI assistance')}/>
                                            }
                                            <ListComponent onClick={handleShowBuyTokenModal} imageSrc={TokenImage}
                                                           altText="Buy your tokens here"
                                                           buttonText={t('Buy credits')}/>
                                            <ListComponent onClick={() => history.push("token-package-purchase-history")} imageSrc={TokenHistory}
                                                           altText="Token purchase history"
                                                           buttonText={t('Token purchase history')}/>
                                        </ul>
                                    </nav>}
                                </div>
                                <div className="col-md-4 token-balance-container d-flex align-items-center justify-content-center font2" onClick={() => history.push('token-history')} style={{cursor: 'pointer'}}>
                                    {tokenBalance}
                                </div>
                            </div>

                            {aiAssistancePopup ? <AiAssistancePopup show={handleShowAiAssistanceModal}
                                                                    onHide={handleCloseAiAssistanceModal}
                                                                    openBuyTokenModal={handleShowBuyTokenModal}
                                                                    setAiAssistanceActive={setAiAssistanceActive}/> : ""}
                            {buyTokenModal ?
                                <BuyTokenPopup show={handleShowBuyTokenModal} onHide={handleCloseBuyTokenModal}
                                               buyTokenPopupConfirm={buyTokenPopupConfirm}
                                               setBuyTokenPopupConfirm={setBuyTokenPopupConfirm}
                                               tokenPackages={tokenPackages}/> : ""}

                            {monthsList !== undefined &&
                                <div className={role !== 'fixer' ? "row ml-2 graph-part" : "row ml-2 fixer-graph"}>
                                    <div className="px-0 width-10 padd-none"><img className="trash-icon mt-1"
                                                                                  src={TrashIcon} alt="trashie"></img>
                                    </div>
                                    <div className="width-90 float-left p-0">
                                        <div className="col-md-4 float-left" onClick={() => setCountList(graphList1)}>
                                            <Graph graphList={graphList1} title={monthsList[0]}></Graph>
                                        </div>
                                        <div className="col-md-4 float-left" onClick={() => setCountList(graphList2)}>
                                            <Graph graphList={graphList2} title={monthsList[1]}></Graph>
                                        </div>
                                        <div className="col-md-4 float-left" onClick={() => setCountList(graphList3)}>
                                            <Graph graphList={graphList3} title={monthsList[2]}></Graph>
                                        </div>
                                    </div>
                                </div>}
                        </div>

                        <div className="row my-2 dashboard-bottom-box">
                            {countList !== undefined &&
                                <div className="col-md-12 col-12 pl-4 pr-4 mb-0 footer text-center">
                                    <div
                                        className={role !== "fixer" ? "col-md-3 pb-2 back-clr float-right count-boxes" : "col-md-3 pb-2 back-clr float-right fixer-count"}
                                        onClick={() => history.push('/trashlist/reject/' + countList['month'])}>
                                        <div className="count-box-text"><h6
                                            className="box-height font5">{t('Rejected')}</h6><h3
                                            className="font2">{countList['reject_count']}</h3></div>
                                    </div>
                                    <div
                                        className={role !== "fixer" ? "col-md-3 pb-2 count-boxes closed float-right" : "col-md-3 pb-2 fixer-count closed float-right"}
                                        onClick={() => history.push('/trashlist/closed/' + countList['month'])}>
                                        <div className="count-box-text"><h6
                                            className="box-height font5">{t('Closed')}</h6><h3
                                            className="font2">{countList['closed_count']}</h3></div>
                                    </div>
                                    <div
                                        className={role !== "fixer" ? "col-md-3 pb-2 count-boxes bg-warning float-right" : "col-md-3 pb-2 fixer-count bg-warning float-right"}
                                        onClick={() => history.push('/trashlist/open/' + countList['month'])}>
                                        <div className="count-box-text"><h6
                                            className="box-height font5">{t('Open')}</h6><h3
                                            className="font2">{countList['open_count']}</h3></div>
                                    </div>
                                    <div
                                        className={role !== "fixer" ? "col-md-3 pb-2 count-boxes bg-secondary float-right" : "col-md-3 pb-2 fixer-count bg-secondary float-right"}
                                        onClick={() => history.push('/trashlist/new/' + countList['month'])}>
                                        <div className="count-box-text"><h6
                                            className="box-height font5">{t('New')}</h6><h3
                                            className="font2">{countList['new_count']}</h3></div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Modal
                    show={lowTokenModal}
                    onHide={() => handleLowTokenModalClose}
                    size="md"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="title-color font-head font3">Low token alert</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{t('Your tokens are running low.')}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="head-sect react-button font-para font8"
                                onClick={() => setLowTokenModal(false)}>
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </section>
    );
}
