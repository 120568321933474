// export const ENV_URL = window.location.origin;
export const ENV_URL = process.env.REACT_APP_BACKEND_URL;
export const REGEX_URL = "/api/";
export const BASE_URL = ENV_URL + REGEX_URL;
export const PAGINATE_BY = 10;

// Follow the below example to add your url endpoints
// EXAMPLE: export const getUserDetails = BASE_URL + 'account/get-user-details';

// add all your new urls from here onwards
export const getUsersActiveLanguage = BASE_URL + "get-users-active-language/";
export const switchLanguage = BASE_URL + "switch-language/";

// Translations API urls starts here
export const getLangaugeList = BASE_URL + "translations/get-all-languages";
export const fetchTranslations = BASE_URL + "translations/fetch-strings";
export const fetchAllTranslations = BASE_URL + "translations/get-all-strings";

// Authentication
export const LoginUrl = BASE_URL + "admin-login/";
export const LogoutLink = "/admin/logout/";
export const ForgotPasswordUrl = BASE_URL + "forgot-password/";

// Users
export const GetAccountDetailsUrl = BASE_URL + "get-account-details/";
export const UploadImageUrl = BASE_URL + "update-profile-image/";

// Fixers
export const GetFixersUrl = BASE_URL + "get-fixers/";
export const CreateFixerUrl = BASE_URL + "create-fixer/";
export const AssignFixerUrl = BASE_URL + "assign-fixer/";
export const DeleteFixerUrl = BASE_URL + "delete-fixer/";

// Complaints
export const GetStatusUrl = BASE_URL + "get-complaint-status/";
export const UpdateStatusUrl = BASE_URL + "change-complaint-status/";
export const GetComplaintsUrl = BASE_URL + "get-complaints/";
export const ComplaintDetailUrl = BASE_URL + "get-complaint-details/";

// Locations
export const LocationsUrl = BASE_URL + "get-locations/";

// Others
export const GraphCountUrl = BASE_URL + "get-graph-data/";
export const AskMoreInfoUrl = BASE_URL + "ask-info/";
export const UpdateAiAssistanceActive = BASE_URL + "update-ai-assistance-active/";
export const GetAiAssistanceActive = BASE_URL + "get-ai-assistance-active/";

export const GetTokenPackages = BASE_URL + "get-token-packages/";
export const BuyTokenPackage = BASE_URL + "buy-token-package/";
export const PaymentSucces = BASE_URL + "payment-succes/";
export const PaymentVerify = BASE_URL + "payment-verify/";
export const PaymentCapture = BASE_URL + "payment-capture/";
export const PaymentNotify = BASE_URL + "payment_notify/";
export const GetTokenHistory = BASE_URL + "token-history/";
export const FetchTokenPackageHistory = BASE_URL + "fetch-package-history/";
export const GenerateInvoice = BASE_URL + "generate-invoice/";




